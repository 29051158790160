import './my-support.component.scss';
import '../../_shared/_assets/scss/_buttons.scss';

import { IStackItemStyles, IStackStyles, IStackTokens, MessageBar, MessageBarButton, MessageBarType, Stack, Text } from 'office-ui-fabric-react';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import React, { useState } from 'react';

import { AuthorizedUser } from '../../_shared/header/authorized-user.model';
import { Button } from 'react-bootstrap';
import { Link } from 'office-ui-fabric-react/lib/';
import { RootState } from '../../_store/store';
import ServiceNowCaseList from './service-now/service-now-case-list.component';
import { ServiceNowCaseRequest } from '../../api/models/service-now-models';
import { ServiceNowClient } from '../../api/service-now-client';
import SupportDocumentList from './document-list.component';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { useConstCallback } from '@uifabric/react-hooks';
import { useSelector } from 'react-redux';
import { ResponseMessageConfig } from './support.models';
import { isNullOrEmpty } from '../../utilities/utility-functions';

const containerStackTokens: IStackTokens = { childrenGap: 5 };

const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
};

/*
    TODO: What does this mean? How to solve this problem (if it exists?) correctly?

    All the local state hook variables need to be in here, unless they are not set in a sequence along with the other states, we want them to be declared and handled separately, 
    so we can minimise the number of re-renders, in cases where more than one of these variables would need to be set
    separately and sequentially which would each result in a re-render of the component or at least a DOM tree diff check
*/

type MySupportHookState = {
    isOpen: boolean,
    isReloadRequired: boolean,
    isFormValid: boolean,
    caseObject: ServiceNowCaseRequest
}

function MySupport() {
    const authorizedUser: AuthorizedUser = useSelector((rootState: RootState) => rootState.session.user);
    const theme = getTheme();
    const [localState, setLocalState] = useState<MySupportHookState>({
        caseObject: {
            description: '',
            title: ''
        },
        isFormValid: false,
        isOpen: false,
        isReloadRequired: false
    });

    const [responseMessageConfig, setResponseMessageConfig] = useState<ResponseMessageConfig>({
        message: '',
        show: false,
        isError: false
    });

    const dismissPanel = useConstCallback(() => {
        setLocalState({
            ...localState,
            caseObject: {} as ServiceNowCaseRequest,
            isOpen: false
        });
    });

    const stackTokensBottomSpacer = { childrenGap: 45, padding: 30 };
    const stackStylesBottomSpacer: Partial<IStackStyles> = { root: { width: 300, paddingTop: 30 } };
    const stackTokensBottom = { childrenGap: 45, padding: 80 };
    const stackStylesBottom: Partial<IStackStyles> = { root: { width: 300, paddingTop: 80 } };
    const stackItemStylesSave: IStackItemStyles = { root: { width: 200, textAlign: 'center' } };

    const stackTokens = { childrenGap: 15, padding: 10 };


    async function handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        let caseObjectCopy = { ...localState.caseObject };
        caseObjectCopy.description = `${authorizedUser.primaryEmail} - ${caseObjectCopy.description}`;
        try {
            await ServiceNowClient.createServiceNowCase(caseObjectCopy);
            setResponseMessageConfig({
                isError: false,
                message: 'The Inquiry has been submitted, successfully.',
                show: true
            });
        } catch (error) {
            setResponseMessageConfig({
                isError: true,
                message: error,
                show: true
            });
        }
        setLocalState({
            ...localState,
            isOpen: false,
            isReloadRequired: true,
            caseObject: {} as ServiceNowCaseRequest,
            isFormValid: false
        });
    }

    function validateCase(request: ServiceNowCaseRequest) {
        let isValid = request && !isNullOrEmpty(request.title) && !isNullOrEmpty(request.description);
        setLocalState({
            ...localState,
            isFormValid: isValid
        });
    }

    function handleCaseTitleChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {
        let stateCopy: MySupportHookState = { ...localState };
        stateCopy.caseObject.title = (newValue ?? '').trim();
        setLocalState(stateCopy);
        validateCase({ ...stateCopy.caseObject });
    }

    function handleDescriptionChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {
        let stateCopy: MySupportHookState = { ...localState };
        stateCopy.caseObject.description = (newValue ?? '').trim();
        setLocalState(stateCopy);
        validateCase({ ...stateCopy.caseObject });
    }

    return (
        <>
            <Stack styles={
                {
                    root: {
                        width: '100%',
                        flex: 'auto',
                        display: 'flex',
                        flexFlow: 'column',
                        paddingLeft: 60,
                        paddingRight: 60,
                        marginTop: 30
                    },
                }
            } tokens={containerStackTokens} className='portal-help-container my-support'>
                <Stack className='top'><Text styles={{
                    root: {
                        fontWeight: 'normal',
                        fontSize: '28px',
                        fontFamily: 'OpenSans-Light'
                    }
                }} variant='xxLarge'>My Support</Text></Stack>
                <Stack tokens={itemAlignmentsStackTokens}>
                    <Text variant='large'>Inquiries</Text>
                    <Link href={''} target='_blank' onClick={() => {
                        setLocalState({
                            ...localState,
                            isOpen: true
                        });
                    }}>Submit Inquiry</Link>
                    <Stack.Item align='auto' tokens={{ padding: 5 }}>
                        {responseMessageConfig.show &&
                            <MessageBar
                                actions={
                                    <div>
                                        <MessageBarButton onClick={() => {
                                            setResponseMessageConfig({ ...responseMessageConfig, show: false });
                                        }}>Ok</MessageBarButton>
                                    </div>
                                }
                                messageBarType={
                                    responseMessageConfig.isError
                                        ? MessageBarType.error
                                        : MessageBarType.success}
                                isMultiline={false}
                                styles={{
                                    root: {
                                        width: 800
                                    }
                                }}
                            >
                                {responseMessageConfig.message}
                            </MessageBar>
                        }
                    </Stack.Item>
                    <Stack.Item align='auto'>
                        <ServiceNowCaseList
                            isReloadRequired={localState.isReloadRequired}
                            authorizedUser={authorizedUser}
                            communicateReloadCompleted={(isReloadComplete) => {
                                if (isReloadComplete) {
                                    setLocalState({
                                        ...localState,
                                        isReloadRequired: false
                                    });
                                }
                            }}
                        />
                    </Stack.Item>
                    <Text variant='large'>Support Documents</Text>
                    <Stack.Item align='auto'>
                        <SupportDocumentList />
                    </Stack.Item>

                </Stack>
            </Stack>

            <Panel
                isOpen={localState.isOpen}
                onDismiss={dismissPanel}
                type={PanelType.smallFixedFar}
                closeButtonAriaLabel="Close"
                headerText="Submit Inquiry"
                styles={{
                    closeButton: { color: theme.palette.red },
                    headerText: {
                        fontFamily: 'OpenSans-Light',
                        fontWeight: 'normal'
                    }
                }}
            >
                <Stack tokens={stackTokens}>
                    <TextField label="Inquiry Title *" defaultValue={localState.caseObject.title} onChange={handleCaseTitleChange} />
                    <TextField label="Description *" multiline autoAdjustHeight onChange={handleDescriptionChange} />
                </Stack>
                <Stack tokens={stackTokensBottomSpacer} styles={stackStylesBottomSpacer}>

                </Stack>
                <Stack horizontalAlign={'center'} tokens={stackTokensBottom} styles={stackStylesBottom}>
                    <Stack.Item styles={stackItemStylesSave}>
                        <div className="position-bootstrap-padding">
                            <Button className='pearl-modal-primary-btn' onClick={handleSubmit} disabled={!localState.isFormValid}>
                                <span>Submit</span>
                            </Button>
                        </div>
                    </Stack.Item>
                </Stack>
            </Panel>
        </>
    );
}
export default MySupport;