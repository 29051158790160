import { EloquaSubscriptionModel, EloquaSubscriptionUpdateModel } from './models/eloqua-subscription-models';

import { ApiClient } from './api-client';

export class EloquaSubscriptionsClient extends ApiClient {
    static baseUrl = `${process.env.REACT_APP_PORTAL_API}/v1`;

    static async get(): Promise<EloquaSubscriptionModel> {
        return await this.fetch<EloquaSubscriptionModel>(`${this.baseUrl}/Eloqua/GetSubscriptions`);
    }
    static async set(request: EloquaSubscriptionUpdateModel): Promise<EloquaSubscriptionModel> {
        this.trackAnalytics('Update Preferences', request);
        return await this.post<EloquaSubscriptionModel>(`${this.baseUrl}/Eloqua/UpdateSubscriptions`, request);
    }
}
