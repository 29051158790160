import { GridColumn as Column, Grid, GridEvent, GridRowClickEvent } from '@progress/kendo-react-grid';
import { ColumnDetail, CommandCellConfig } from '../../interfaces/types';
import React, { useState } from 'react';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

import { MyCommandCell } from './my-command-cell';
import { StatusIndicatorCell } from './status-indicator-cell';
import { formatDateTimeToMMDDYYYY } from '../../utilities/utility-functions';

// TODO move these types into a types file
interface IncomingProps {
    incomingGridDataProps: any[];
    selectedId: any | undefined;
    idKey: string;
    columnDetails: Array<ColumnDetail>;
    sortFields: SortDescriptor[];
    CSSClass?: string;
    pageSize?: number;
    blockSorting?: boolean;
    commandCellConfig?: CommandCellConfig;
}

interface IOutgoingPropsAndEvents {
    handleRowClick?: ((event: GridRowClickEvent) => void) | undefined
    handleGridScroll?: ((event: GridEvent) => void) | undefined
    handleIconRender?: ((eventRowData: any, columnDetail: ColumnDetail) => void) | undefined
}

interface KendoGridState {
    data: any[];
}

interface ReduxProps extends IncomingProps, IOutgoingPropsAndEvents { }


const ReadonlyKendoGrid = (props: ReduxProps) => {

    const { incomingGridDataProps, idKey, columnDetails, CSSClass, sortFields, commandCellConfig,
        handleRowClick, handleGridScroll } = props;

    const [sort, setSort] = useState<SortDescriptor[]>([...sortFields]);

    const state: KendoGridState = {
        data: Object.assign([], { ...incomingGridDataProps }, { inEdit: false }),
    };

    return (
        <Grid
            data={orderBy(state.data.map((item) => ({
                ...item,
                selected: props.selectedId ? item[idKey] === props.selectedId[idKey] : false,
                wonDate: formatDateTimeToMMDDYYYY(item.wonDate)
            })
            ), sort)}
            sortable
            sort={sort}
            onRowClick={handleRowClick}
            onScroll={handleGridScroll}            
            onSortChange={(e) => {
                if (props.blockSorting) { }
                else {
                    setSort([...e.sort]);
                }
            }}            
            selectedField="selected"
            className={CSSClass ? `kendo-react-grid ${CSSClass}` : 'kendo-react-grid'}
        >
            {
                columnDetails.map((columnDetail) => {
                    // TODO - Need to ship this outside of this component and make it loosely coupled
                    if (columnDetail.title === 'ACCESS') {
                        return (
                            <Column
                                key={'id'}
                                title={columnDetail.title}
                                cell={StatusIndicatorCell()}                                                                
                                width='130px' />);
                    } else if (columnDetail.isIndicator) {
                        return (
                            <Column
                                key={'id'}
                                title={columnDetail.title}
                                cell={MyCommandCell({
                                    handleIconRender: props.handleIconRender,
                                    commandCellConfig: commandCellConfig,
                                    columnDetail: columnDetail
                                })}
                                width={columnDetail.width}
                            />
                        );
                    } else if (columnDetail.customCallbackEnabled) {
                        return (
                            <Column
                                key={'id'}
                                cell={MyCommandCell({
                                    handleIconRender: columnDetail.customCallback,
                                    commandCellConfig: commandCellConfig,
                                    columnDetail: columnDetail
                                })}
                                width={columnDetail.width}
                            />
                        );
                    } else {
                        return <Column className={columnDetail.hasOwnProperty('customcss') ? columnDetail.customcss : ''} key={idKey} field={columnDetail.field} title={columnDetail.title} format={columnDetail.format} width={columnDetail.width} />;
                    }
                })
            }
        </Grid>
    );
};

export default ReadonlyKendoGrid;