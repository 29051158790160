import './content-card.component.scss';

import React, { ReactNode } from 'react';
import * as styles from '../fluentui/fluentui-pearl-styling';
import { TrackedLink, TrackingTokens } from '../../_shared/tracked-link.component';

import { Icon } from '@fluentui/react/lib/Icon';
import { AuthorizedUser } from '../../_shared/header/authorized-user.model';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

export interface ContentCardData {
    content: {
        id: string,
        type: string,
        linkUrl: string,
        imageUrl: string,
        title: string,
        subtitle?: string,
        category?: string,
        profileService?: string,
        profileSiteInterest?: string,
        profileIndustry?: string
        date: string;
        caseStudyImage1: string;
        caseStudyImage2: string;
        caseStudyImage3: string;
        caseStudyDescription1: string;
        caseStudyDescription2: string;
        caseStudyDescription3: string;
    },
    detail: ReactNode,
    action?: ReactNode,
    footer?: ReactNode,
    trackingTokens: TrackingTokens
    user: AuthorizedUser
}

function ContentCard(props: ContentCardData) {
    const x = props.content;

    return (
    <>
        {x.type !== 'Case Study' ?
            <div className="bookmark-wrapper" data-siteinterest={x.profileSiteInterest} data-industry={x.profileIndustry} data-service={x.profileService} key={x.id}>
                <div className="bookmark hover-box" key={x.id}>
                    <TrackedLink href={x.linkUrl} trackingTokens={props.trackingTokens} user={props.user}>
                        {x.type === 'Video' &&
                            <Icon iconName="TriangleSolidRight12" className="icon icon-play" />
                        }
                        <div className="header-image">
                            <img src={x.imageUrl} alt="-" />
                        </div>
                        <div className="text">
                            {x.category && <h2>{x.category}</h2>}
                            {x.title && <div>{x.title}</div>}
                            {props.detail}
                        {x.type === 'News' &&
                            <div className="news-Date">{x.date}</div>
                        }
                        </div>
                    </TrackedLink>
                    {props.action}
                </div>
                {props.footer}
            </div> : 
            <div className="bookmark-wrapper casestudy-bookmark" data-siteinterest={x.profileSiteInterest} data-industry={x.profileIndustry} data-service={x.profileService} key={x.id}>
            <div className="bookmark hover-box" key={x.id}>
                <TrackedLink href={x.linkUrl} trackingTokens={props.trackingTokens} user={props.user}>
                    <Stack horizontal>
                        <Stack.Item styles={styles.stackItemStyles} className="leftStack">
                            <div className="category">{x.category}</div>
                            <div className="title">{props.detail}{x.title}</div>
                        </Stack.Item>
                        <Stack.Item styles={styles.stackItemStyles} className="righttStack">
                                {x.caseStudyImage1 && <div className="image"><img src={x.caseStudyImage1} alt="-" /></div>}
                                {x.caseStudyDescription1 && <div className="rightText">{x.caseStudyDescription1}</div>}

                                {x.caseStudyImage2 && <div className="image"><img src={x.caseStudyImage2} alt="-" /></div>}
                                {x.caseStudyDescription2 && <div className="rightText">{x.caseStudyDescription2}</div>}

                                {x.caseStudyImage3 && <div className="image"><img src={x.caseStudyImage3} alt="-" /></div>}
                                {x.caseStudyDescription3 && <div className="rightText">{x.caseStudyDescription3}</div>}
                        </Stack.Item>
                    </Stack>
                </TrackedLink>
                {props.action}
            </div>
            {props.footer}
        </div>}
    </>
    );
}

export default ContentCard;