import { ApiClient } from './api-client';
import PearlNotification from './models/notification.models';

export class NotificationsClient extends ApiClient {
    static baseUrl = `${process.env.REACT_APP_PORTAL_API}/v1`;

    static async get(): Promise<PearlNotification[]> {
        return await this.fetch<PearlNotification[]>(`${this.baseUrl}/notification`);
    }

    static async getBells(): Promise<PearlNotification[]> {
        return await this.fetch<PearlNotification[]>(`${this.baseUrl}/notification?Type=1`);
    }

    static async dismiss(notificationIds: string[]): Promise<any> {
        return await this.post<any>(`${this.baseUrl}/notification/dismiss`, notificationIds);
    }

    static async count(): Promise<number> {
        const records = await this.get();
        return records.length;
    }

    static async countBells(): Promise<number> {
        const records = await this.getBells();
        return records.length;
    }

    static async getActiveAnnouncement(): Promise<PearlNotification[]> {
        return await this.fetch<PearlNotification[]>(`${this.baseUrl}/notification?Type=3&IsPublishingNow=true`);
    }
}
