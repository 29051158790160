import './side-nav-list.component.scss';

import { Icon } from '@fluentui/react/lib/Icon';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { RootState } from '../_store/store';
import { useSelector } from 'react-redux';

function SideNavList() {
    const session = useSelector((state: RootState) => state.session);

    return (
        <div className="side-nav-list">
            <NavLink
                to="/my-products"
                activeClassName={'active-navigation-sidenav'}
                className={session.isNavigationOpen ? 'expanded nav-link' : 'nav-link'}>
                <Icon iconName="TabCenter" className="icon" />
                <span className="not-bold">My Products</span>
            </NavLink>
            <NavLink
                to="/my-content"
                activeClassName={'active-navigation-sidenav'}
                className={session.isNavigationOpen ? 'expanded nav-link' : 'nav-link'}>
                <Icon iconName="SingleBookmark" className="icon" />
                <span className="not-bold">My Content</span>
            </NavLink>
            {/*<NavLink to="/my-support" activeClassName={'active-navigation-sidenav'} className={session.isNavigationOpen ? 'expanded nav-link' : 'nav-link'}>
                <Icon iconName="SurveyQuestions" className="icon help-doc-icon" />
                <span className="not-bold">My Support</span>
            </NavLink>
                Commenting as per PBI 151452 */}
            {(session.user.Role?.toLowerCase().includes('admin')) && <NavLink to="/admin" activeClassName={'active-navigation-sidenav'} className={session.isNavigationOpen ? 'expanded nav-link' : 'nav-link'}>
                <Icon iconName="Settings" className="icon help-doc-icon" />
                <span className="not-bold">Admin</span>
            </NavLink>}

        </div>
    );
}

export default SideNavList;
