import { RematchDispatch, RematchRootState, init } from '@rematch/core';
import { RootModel, models } from '.';

import { AuthorizedUser } from '../_shared/header/authorized-user.model';
import { Bookmark } from '../api/models/bookmark.models';
import { TrackingTokens, defaultTrackingTokens } from '../_shared/tracked-link.component';

export const initializeStore = (
    user: AuthorizedUser,
    trackingTokens: TrackingTokens,
    insights: Bookmark[]
) => {
    models.session.state.user = user;
    models.session.state.trackingTokens = defaultTrackingTokens;
    models.session.state.userDestination = '/my-content';
    models.session.state.insights = insights;
    models.session.state.corpLinks = {
        duffAndPhelps: `${process.env.REACT_APP_LINK_DUFF_AND_PHELPS}`,
        krollBusinessServices: `${process.env.REACT_APP_LINK_KROLL_BUSINESS_SERVICES}`,
        kroll: `${process.env.REACT_APP_LINK_KROLL}`
    };

    return init({ models });
};

export type RootState = RematchRootState<RootModel>
export type Dispatcher = RematchDispatch<RootModel>