import './header.component.scss';

import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import React, { useEffect } from 'react';
import { TrackedLink, TrackingTokens } from '../tracked-link.component';

import { AppList } from './app-list';
import { AuthorizedUser } from './authorized-user.model';
import { ReactComponent as DuffAndPhelpsLogo } from '../_assets/images/logo-web.svg';
import { Icon } from '@fluentui/react/lib/Icon';
import MyAccount from './my-account/my-account.component';
import NotificationsBell from './my-notifications/my-notifications.component';

function useFlags(...flags: number[]): [boolean[], (flag: number, state?: boolean) => void] {
    const properties: boolean[] = [];
    const functions: Function[] = [];
    for (let item of flags) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [stateProperty, stateFunction] = React.useState(false);
        properties[item] = stateProperty;
        functions[item] = stateFunction;
    }

    return [
        properties,
        (flag: number, state?: boolean) => {
            functions[flag](state == null ? !properties[flag] : state);
        }
    ];
}

enum Show { Profile, Navigation }

const panelStyle = {
    subComponentStyles: {
        closeButton: {
            root: {
                color: '#eb3023',
            },
            icon: {
                fontSize: 24
            }
        },
    }
};

export interface CorpLinks {
    duffAndPhelps: string,
    kroll: string,
    krollBusinessServices: string
}

function Header(props: { user: AuthorizedUser, links: CorpLinks, tokens: TrackingTokens }) {
    const ref = React.useRef(null);
    const [flags, setFlag] = useFlags(Show.Profile, Show.Navigation);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (event.target.className == null || event.target.className.includes == null) return;

            const isAppsIcon = event.target.className.includes('apps-icon');
            if (isAppsIcon !== true && ref.current && !(ref.current as any).contains(event.target)) {
                setFlag(Show.Navigation, false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [setFlag]);

    return (
        <>
            <header>
                <TrackedLink href={props.links.duffAndPhelps} trackingTokens={props.tokens} user={props.user}><DuffAndPhelpsLogo className="logo" /></TrackedLink>
                <div className="content">
                    <nav className="primary">
                        <TrackedLink href={props.links.kroll} trackingTokens={props.tokens} user={props.user}>Kroll</TrackedLink>
                        <TrackedLink href={props.links.duffAndPhelps} trackingTokens={props.tokens} user={props.user}>Duff &amp; Phelps</TrackedLink>
                        <TrackedLink href={props.links.krollBusinessServices} trackingTokens={props.tokens} user={props.user}>Kroll Business Services</TrackedLink>
                    </nav>
                    <nav className="secondary">
                        <div className="icons">
                            <NotificationsBell />
                            <Icon iconName="Waffle" className={flags[Show.Navigation] ? 'icon apps-icon icon-active' : 'icon apps-icon'} onClick={() => setFlag(Show.Navigation)} />
                            <span ref={ref} className="apps-list-container">
                                {flags[Show.Navigation] ? <AppList user={props.user} links={props.links} tokens={props.tokens} /> : null}
                            </span>
                            <span className="user-icon" onClick={() => setFlag(Show.Profile, true)}>{props.user.Initials}</span>
                            <Panel
                                isLightDismiss
                                isOpen={flags[Show.Profile]}
                                onDismiss={() => setFlag(Show.Profile, false)}
                                closeButtonAriaLabel="Close"
                                type={PanelType.custom}
                                customWidth="600px"
                                styles={panelStyle}>
                                <MyAccount user={props.user} />
                            </Panel>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
};

export default Header;