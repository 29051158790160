import { ApiClient } from './api-client';
import { Bookmark } from './models/bookmark.models';
import { IPersonalizationResult } from '../app/my-products/other-services.component';

export class PersonalizationClient extends ApiClient {
    static baseUrl = `${process.env.REACT_APP_PORTAL_API}/v1`;

    static async get(): Promise<IPersonalizationResult> {
        return await this.fetch<IPersonalizationResult>(`${this.baseUrl}/personalization/areaswecanhelp`);
    }

    static async getInsights(): Promise<{ eloquaId: string | undefined, insights: Bookmark[] }> {
        try {
            return await this.fetch<any>(`${this.baseUrl}/personalization/insights`);
        }
        catch (error) {
            return new Promise(resolve => resolve({ eloquaId: undefined, insights: [] }));
        }
    }
}