import { AuthorizedUser, defaultUser } from '../../_shared/header/authorized-user.model';

export interface TokenUser {
    token?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    country?: string;
    company?: string;
    'https://auth-qa.kroll.com/app_metadata'?: Iuser;
    'https://auth-uat.kroll.com/app_metadata'?: Iuser;
    'https://auth.kroll.com/app_metadata'?: Iuser;
}

export interface Iuser {
    user: AuthorizedUser;
}

export function parseJwtToken(encoded: string): TokenUser {
    try {
        return JSON.parse(atob(encoded.split('.')[1]));
    } catch (e) {
        return {
            'https://auth.kroll.com/app_metadata': {
                user: defaultUser
            }
        };
    }
}