import React, { useEffect, useState, useRef } from 'react';
import CreateAnnouncement from './create-announcement';
import EditAnnouncement from './edit-announcement';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import * as styles from '../fluentui/fluentui-pearl-styling';
import { SuccessModalConfig } from '../../interfaces/types';
import SuccessModal from '../common/response-handlers/success-modal';
import SuccessResponseModalBody from '../common/success-response-modal-body';
import './admin-component.scss';
import AnnouncementsGrid from './grids/announcements-grid';
import { Announcement, defaultAnnouncement } from '../../api/models/announcement.models';
import { AnnouncementClient } from './../../api/announcement.client';
import { formatDateTimeToMMDDYYYY } from '../../utilities/utility-functions';

const AdminComponent = () => {
    const [isCreatePanelOpen, setIsCreatePanelOpen] = React.useState(false);
    const headerText = 'Create Announcement';
    const EditheaderText = 'Announcement Details';
    const panelWidth = '454px';
    const [successModalConfig, setSuccessModalConfig] = useState<SuccessModalConfig>({ message: '', show: false });
    const [announcementData, setAnnouncementData] = useState<Array<Announcement>>([]);
    const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);  
    const [currentAnnouncement, setCurrentAnnouncement] = useState(defaultAnnouncement as Announcement);
    const [triggerChild, setTriggerChild] = useState(0);
    const previousAnnouncementData = usePrevious(announcementData);


    const SuccessfulAnnoncementCreation = () => <SuccessResponseModalBody
        responseMessage={successModalConfig.message}
        onDoneClick={() => {
            setSuccessModalConfig({
                message: '',
                show: false
            });
        }}
    />;

    useEffect(() => {
        getAnnouncements();
    }, []);

    useEffect(() => {
        setTriggerChild(triggerChild+1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [announcementData]);
    // Hook
    function usePrevious(value: any) {
        // The ref object is a generic container whose current property is mutable ...
        // ... and can hold any value, similar to an instance property on a class
        const ref = useRef();
        
        // Store current value in ref
        useEffect(() => {
        ref.current = value;
        }, [value]); // Only re-run if value changes
        
        // Return previous value (happens before update in useEffect above)
        return ref.current;
    }
    async function getAnnouncements(){
        const announcements = await AnnouncementClient.get();

        const formattedClientAnnouncements = announcements.map(p => {
            return {
                ...p, 
                publishDate: formatDateTimeToMMDDYYYY(p.publishDate),
                expirationDate: formatDateTimeToMMDDYYYY(p.expirationDate)
            };
        });
        setAnnouncementData([...formattedClientAnnouncements]);
    }
    function handleCreateAnnouncement() {
        setIsCreatePanelOpen(true);
    }

    function handleCreateAnnouncementComplete(): void {
        setIsCreatePanelOpen(false);
        setSuccessModalConfig({
            message: 'Announcement created successfully',
            show: true
        });
        getAnnouncements();
    }
    function handleEditAnnouncementComplete(): void {
        setIsCreatePanelOpen(false);
        setSuccessModalConfig({
            message: 'Announcement edited successfully',
            show: true
        });
        getAnnouncements();
    }
    function handleEditAnnouncement(currentAnnouncementfromgrid: Announcement): void | undefined {
        setCurrentAnnouncement(currentAnnouncementfromgrid);
        setIsEditPanelOpen(true);
    }
    return (
        <>
            <div className="admin-container">
                <div className="content-header flex-container">
                    <h1>Admin</h1>
                    <div className="action-item-container">
                        <span className="action-item" onClick={handleCreateAnnouncement}>Create New Announcement</span>
                    </div>

                    <Panel
                        isOpen={isCreatePanelOpen}
                        onDismiss={() => setIsCreatePanelOpen(false)}
                        type={PanelType.custom}
                        customWidth={panelWidth}
                        closeButtonAriaLabel="Close"
                        headerText={headerText}
                        styles={styles.panelStyles}
                    >
                        <CreateAnnouncement  
                            createAnnouncementProcess={handleCreateAnnouncementComplete}
                            handleCancelClick={() => setIsCreatePanelOpen(false)}/>
                    </Panel>

                    <SuccessModal
                        component={SuccessfulAnnoncementCreation}
                        isShowing={successModalConfig.show}
                        onHide={() => setSuccessModalConfig({
                            show: false,
                            message: ''
                        })}
                        modalHeaderText=''
                    />
                </div>
                <div className="content">
                    <h2>Client Workbench Announcements</h2>
                    {
                       (JSON.stringify(announcementData) === JSON.stringify(previousAnnouncementData)) && 
                        <AnnouncementsGrid 
                        announcementData={announcementData}
                        editAnnouncementProcess={handleEditAnnouncement}
                        applicationType='client'
                        triggerChild={triggerChild} />
                    }
                    
                    <h2>Employee Workbench Announcements</h2>
                    {
                       (JSON.stringify(announcementData) === JSON.stringify(previousAnnouncementData)) && 
                        <AnnouncementsGrid 
                        announcementData={announcementData}
                        editAnnouncementProcess={handleEditAnnouncement}
                        applicationType='employee'
                        triggerChild={triggerChild}  />
                    }
                    
                    <h2>Portal Announcements</h2>
                    {
                       (JSON.stringify(announcementData) === JSON.stringify(previousAnnouncementData)) && 
                        <AnnouncementsGrid 
                        announcementData={announcementData}
                        editAnnouncementProcess={handleEditAnnouncement}
                        applicationType='portal'
                        triggerChild={triggerChild}  />
                    }
                </div>
            </div>

            <Panel
                isOpen={isEditPanelOpen}
                onDismiss={() => setIsEditPanelOpen(false)}
                type={PanelType.custom}
                customWidth={panelWidth}
                closeButtonAriaLabel="Close"
                headerText={EditheaderText}
                styles={styles.panelStyles}
            >
                <EditAnnouncement
                    currentAnnouncement={currentAnnouncement}  
                    editAnnouncementProcessComplete={handleEditAnnouncementComplete}
                    handleCancelClick={() => setIsEditPanelOpen(false)}/>
            </Panel>
        </>
    );
};

export default AdminComponent;