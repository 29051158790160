import { AuthorizedUser } from '../_shared/header/authorized-user.model';
import { Bookmark } from '../api/models/bookmark.models';
import { TrackingTokens, defaultTrackingTokens } from '../_shared/tracked-link.component';

export interface Session {
    user: AuthorizedUser;
    trackingTokens: TrackingTokens;
    insights: Bookmark[];
    corpLinks: Links;
    isNavigationOpen: boolean;
    isNavigationClosing: boolean;
    userDestination: string;
}

export interface SessionTracking {
    sitecoreId?: string;
    eloquaId?: string;
}

interface Links {
    duffAndPhelps: string,
    kroll: string,
    krollBusinessServices: string
}

const isNavigationOpenKey = 'Portal.isNavigationOpen';
const isNavigationOpen = JSON.parse(localStorage.getItem(isNavigationOpenKey) as string) ?? true;


export const session = {
    state: {
        user: {} as AuthorizedUser,
        trackingTokens: defaultTrackingTokens,
        insights: [],
        corpLinks: {
            duffAndPhelps: '',
            kroll: '',
            krollBusinessServices: ''
        },
        isNavigationOpen: isNavigationOpen,
        isNavigationClosing: false,
        userDestination: '/my-content',
    } as Session,
    reducers: {
        toggleNavigation: (state: Session) => {
            if (state.isNavigationOpen === true) {
                state.isNavigationClosing = true;
            } else {
                state.isNavigationClosing = false;
            }
            state.isNavigationOpen = !state.isNavigationOpen;
            localStorage.setItem(isNavigationOpenKey, JSON.stringify(state.isNavigationOpen));
            return { ...state };
        },
        getInsightsData: (state: Session, action: Array<Bookmark>) => {
            state.insights = action;
            return { ...state };
        },
        LoadUserData: (state: Session, action: AuthorizedUser) => {
            state.user = action;
            return { ...state };
        },
        UpdateTrackingTokens: (state: Session, action: TrackingTokens) => {
            state.trackingTokens = action;
            return { ...state };
        },
    }
};
