import React, { Component } from 'react';

type ErrorBoundaryProps = {
  errorMessage?: string;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state = {
    error: null
  }
  
  message = this.props.errorMessage || 'Something Broke!';

  static getDerivedStateFromError(error: any) {
  // Update state so next render shows fallback UI.
  return { error: error };
}

  componentDidCatch(error: any, info: any) {
  // Log the error to an error reporting service
}

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return <p>{this.message}</p>;
    }
    return this.props.children;
  }
};