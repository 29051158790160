import './bell-notification.component.scss';

import { IconButton, Persona, PersonaSize } from 'office-ui-fabric-react';

import React from 'react';

export interface BellNotificationData {
        notificationId: string,
        OriginatingUser: {
            FirstName: string,
            LastName: string
        },
        updatedOn: Date,
        Subject: string,
        Message: string,
        Link: string
}



function BellNotification(props: { notification: BellNotificationData, dismiss: (id: string) => void } ) {
    function OnNotificationClick(id: string, link: string) {
        props.dismiss(id);
        window.open(link, '_self');
    }

    function OnNotificationDismiss(id: string) {
        props.dismiss(id);
    }
    
    const x = props.notification;

    const initials = x.OriginatingUser.FirstName[0] + x.OriginatingUser.LastName[0];

    const DismissButton = () => 
        <IconButton 
            onClick={(e) => OnNotificationDismiss(x.notificationId)} 
            iconProps={{ iconName: 'Cancel', styles: { root: { color: 'red' }}}} 
            title="Dismiss" 
            ariaLabel="Dismiss" 
        />;

    return (
        <div className="bell-notification-message" key={x.notificationId}>
            <div className="bell-message-header">
                <div className="bell-message-originator-persona">
                    <Persona
                        imageInitials={initials}
                        size={PersonaSize.size48}>    
                    </Persona>
                </div>
                <div className="bell-message-title">               
                    <div className="bell-message-title-line">
                        <div className="bell-message-title-text">
                            <div className="bell-message-originator-name">{x.OriginatingUser.FirstName} {x.OriginatingUser.LastName}</div>
                            <div className="bell-message-origination-date">{x.updatedOn.toLocaleDateString()}</div>
                        </div>
                        <div className="bell-message-dismiss">
                            <DismissButton />
                        </div>
                    </div>
                    <div className="bell-message-subject-line" onClick={(e) => OnNotificationClick(x.notificationId, x.Link)}>{x.Subject}</div>
                </div>
            </div>
            <div className="bell-message-body" onClick={(e) => OnNotificationClick(x.notificationId, x.Link)}>{x.Message}</div>
        </div>
    );
}

export default BellNotification;
