
import React, { useEffect, useState } from 'react';

import { ColumnDetail, CommandCellConfig, ResponseMessageConfig } from '../../../interfaces/types';
import { MessageBar, MessageBarButton, MessageBarType } from 'office-ui-fabric-react';
import ReadonlyKendoGrid from '../../../_shared/grids/readonly-kendo-grid';
import { GridRowClickEvent } from '@progress/kendo-react-grid';
import { Announcement } from '../../../api/models/announcement.models';
import { ReactComponent as Pencil } from '../../../_shared/_assets/icons/pencil.svg'; 
import { ReactComponent as AnnouncementIcon } from '../../../_shared/_assets/icons/announcement.svg'; 
import '../admin-component.scss';
import { StringConstants } from '../../../utilities/constants';

type announcementsGridProps = {
    announcementData: Announcement[];
    editAnnouncementProcess: (currentAnnouncement: Announcement) => void | undefined;
    applicationType: string;
    triggerChild: number; 
};

const AnnouncementsGrid = (props: announcementsGridProps) => {

    const {announcementData, editAnnouncementProcess, applicationType, triggerChild} = props;
    const [gridData, setGridData] = useState<Array<Announcement>>([]);
    const [responseMessageConfig, setResponseMessageConfig] = useState<ResponseMessageConfig>({
        message: '',
        show: false,
        isError: false
    });

    const columnDetails: Array<ColumnDetail> = [
        { field: 'description', title: 'Accouncement Text', isIndicator: true },
        { field: 'publishDate', title: 'Publish DATE'},
        { field: 'expirationDate', title: 'UnPublish DATE' },
        { field: 'isMissingMandatoryField', title: '', isIndicator: true },
    ];
    const commandCellConfig: CommandCellConfig = {
        addNewIcon: false,
        editIcon: false,
        addSubIcon: false,
        deleteIcon: false,
        iconRender: true
    };
    
    useEffect(() => {
        getAnnouncements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getAnnouncements(){
        if(applicationType === StringConstants.Client){
            setGridData(announcementData.filter(q => q.availableInClientWorkbench));
        }else if(applicationType === StringConstants.Employee){
            setGridData(announcementData.filter(q => q.availableInEmployeeWorkbench));
        }else if(applicationType === StringConstants.Portal){
            setGridData(announcementData.filter(q => q.availableInPortal));
        }
    }

    function handleEditRowClick(currentAnnouncement: GridRowClickEvent): void {
        editAnnouncementProcess(currentAnnouncement.dataItem);
    }

    function handleIconRender(eventRowData: any, columnDetail: ColumnDetail) {
        let todayDate = new Date().getTime();
        let expirationDate = new Date(eventRowData.expirationDate).getTime();
        let publishDate = new Date(eventRowData.publishDate).getTime();
        let isActive = (todayDate >= publishDate) && (todayDate <= expirationDate);

        if(columnDetail.field === 'description'){
            return (
                <>
                    {isActive && <AnnouncementIcon 
                    className="my-command-cell-icon" />}
                    <span>{eventRowData.description}</span>
                </>
            );
        }
        else if(columnDetail.field === 'isMissingMandatoryField'){
            return (
                <Pencil
                    className="my-command-cell-icon"
                    onClick={() => {
                        editAnnouncementProcess(eventRowData);
                    }}
                />
            );
        }
        return <></>;
    }
    
    return (
        <>
            <div key={triggerChild}>
                    {responseMessageConfig.show &&
                        <MessageBar
                            actions={
                                <div>
                                    <MessageBarButton onClick={() => {
                                        setResponseMessageConfig({ ...responseMessageConfig, show: false });
                                    }}>Ok</MessageBarButton>
                                </div>
                            }
                            messageBarType={
                                responseMessageConfig.isError
                                    ? MessageBarType.error
                                    : MessageBarType.success}
                            isMultiline={false}
                        >
                            {responseMessageConfig.message}
                        </MessageBar>
                    }
                    <ReadonlyKendoGrid
                      selectedId={{}}
                      idKey={'description'}
                      sortFields={[
                          { field: 'description', dir: 'desc' }
                      ]}
                      incomingGridDataProps={gridData}
                      columnDetails={columnDetails}
                      commandCellConfig={commandCellConfig}
                      handleIconRender={handleIconRender}
                      handleRowClick={handleEditRowClick}
                    />
            </div>
        </>
    );
};

export default AnnouncementsGrid;