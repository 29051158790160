import { ApiClient } from './api-client';
import { User } from './models/user.models';

export class UserClient extends ApiClient {
    static baseUrl = `${process.env.REACT_APP_PORTAL_API}/v1`;

    static async get(): Promise<User> {
        return await this.fetch<User>(`${this.baseUrl}/user`);
    }

    static async set(id: string): Promise<any> {
        return await this.post<string>(`${this.baseUrl}/user/sitecore`, id);
    }
}
