import React from 'react';

function NotFound() {
    return (
        <div>
            <p>404 Page Not Found</p>
        </div>
    );
}

export default NotFound;