import { Icon } from '@fluentui/react/lib/Icon';
import React from 'react';

type ResponseBodyProps = {
    responseMessage: string | JSX.Element;
    onDoneClick: () => void
}

const SuccessResponseModalBody = (props: ResponseBodyProps) => {
    const { responseMessage } = props;

    return (
        <div>
            <div className='text-center'>
                <Icon iconName='SkypeCircleCheck' className='badge-blue'></Icon>
            </div>
            <p className="paragraph-note-bold">{responseMessage}</p>

            <div className='text-center'>
                <button className='div-center' onClick={props.onDoneClick}>
                    <span className="action-button-solid-large"> Done </span>
                </button>
            </div>
        </div>
    );
};

export default SuccessResponseModalBody;