import './editable-kendo-grid.scss';

import { GridColumn as Column, Grid, GridEvent, GridItemChangeEvent, GridPageChangeEvent, GridRowClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownCell } from './drop-down-cell';
import { GridCellProps } from '@progress/kendo-react-grid';
import { MyCommandCell } from './my-command-cell';
import { ColumnDetail, CommandCellConfig, ColumnConfiguration, Pagination } from './grid.model';

interface IncomingProps {
    incomingGridDataProps: any[];
    hasEditedItemProp: boolean;
    selectedId?: any | undefined;
    columnDetails: Array<ColumnDetail>;
    gridItemName: string;
    pagination?: Pagination
    defaultSortByColumn?: SortDescriptor;
    gridToolBarActionName?: string;
    isGridToolBarNeeded: boolean;
    commandCellConfig?: CommandCellConfig;
    isCommandCellNeeded: boolean;
}

// Handle the relevant events in the consuming component 
interface OutgoingPropsAndEvents {
    handleRowClick: ((event: GridRowClickEvent) => void) | undefined
    handleEditClick?: ((eventRowData: any) => void) | undefined
    handleItemChange?: ((event: GridItemChangeEvent) => void) | undefined
    handleUpdate?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
    handleRemoveItem?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
    handleCancelCurrentChanges?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
    handleAddNew?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
    handleAddClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
    handleDiscard?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
    handleCancel?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
    handleGridToolBarButtonClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined
    handlePageChange?: ((event: GridPageChangeEvent) => void) | undefined
    handleGridScroll?: ((event: GridEvent) => void) | undefined
}

// type any used intentionally
interface GridData {
    data: any[];
    total: number;
    skip: number;
}

interface ConsolidatedProperties extends IncomingProps, OutgoingPropsAndEvents {

}

let editField = 'inEdit';
let CommandCell: any;
let state: GridData = {
    data: new Array<any>(),
    total: 0,
    skip: 0
};


const EditableKendoGrid = (props: ConsolidatedProperties) => {
    const { incomingGridDataProps,
        hasEditedItemProp,
        columnDetails,
        pagination,
        defaultSortByColumn,
        gridToolBarActionName,
        isGridToolBarNeeded,
        commandCellConfig } = props;
    const totalRecordCount = (pagination && pagination.totalRecords ? pagination.totalRecords : 0);
    const pageSize = (pagination && pagination.pageSize ? pagination.pageSize : 5);
    const pageNumber = (pagination && pagination.pageNumber ? pagination.pageNumber : 1);
    const skipCount = pageNumber === 1 ? 0 : (totalRecordCount - (pageSize * pageNumber) + 1);
    const [sort, setSort] = useState<SortDescriptor[]>([
        {
            field: defaultSortByColumn !== undefined
                ? defaultSortByColumn.field
                : 'name',
            dir: defaultSortByColumn !== undefined
                ? defaultSortByColumn.dir
                : 'asc'
        }
    ]);

    if (incomingGridDataProps !== undefined && incomingGridDataProps.length > 0) {
        state = {
            data: Object.assign([],
                { ...incomingGridDataProps }),
            skip: skipCount,
            total: totalRecordCount
        };
    }

    CommandCell = MyCommandCell({
        edit: props.handleEditClick,
        remove: props.handleRemoveItem,
        add: props.handleAddClick,
        discard: props.handleDiscard,
        update: props.handleUpdate,
        cancel: props.handleCancel,
        editField: editField,
        commandCellConfig: commandCellConfig,
        clickableField: '',
        openFormOnClick: false
    });

    // dropDownData can be passed in as a part of the incoming props too and further assigned here.
    const MyCustomDropDownCell = (props: GridCellProps) => <DropDownCell {...props} dropDownData={[]} />;

    let columnConfigs = new Array<ColumnConfiguration>();

    let dispOrder = 2;

    // TODO: Accessing state.data[0] seems very wrong
    // TODO: Using Object.keys in combination with map in order to product side-effects is extremely wrong
    // TODO- this column configuration can be persisted and could be a user specific setting with a default version for the users who have not set this up. 
    function generateColumns() {
        Object.keys(state.data[0]).map(
            // eslint-disable-next-line array-callback-return
            (key, index) => {
                if (columnDetails.findIndex(x => x.field === key) !== -1) {
                    const columnConfig: ColumnConfiguration = {
                        columnIndex: index,
                        columnName: key,
                        displayName: key.charAt(0).toUpperCase() + key.substring(1),
                        isActionButton: false,
                        isDatePicker: (key.toLocaleLowerCase().indexOf('date') !== -1) ? true : false,
                        isDropDown: (key === 'author') ? true : false,
                        isEditable: (key === 'category') ? true : false,
                        isSelectable: (key === 'selected') ? true : false,
                        displayOrder: (key === 'author') ? 1 : dispOrder++
                    };
                    columnConfigs.push(columnConfig);
                }
            });
        columnConfigs.push({
            columnIndex: columnConfigs.length,
            columnName: '',
            displayName: '',
            displayOrder: columnConfigs.length,
            isActionButton: true,
            isDatePicker: false,
            isDropDown: false,
            isEditable: false,
            isSelectable: false
        });

        const sortedColumnConfig = columnConfigs.sort(config => config.displayOrder);
        return sortedColumnConfig.map(colConfig => {
            return createColumnComponent(colConfig);
        });
    }

    function createColumnComponent(columnConfig: ColumnConfiguration): any {
        if (columnConfig.isDropDown) {
            return <Column
                key={columnConfig.columnIndex}
                field={columnConfig.columnName}
                title={columnConfig.displayName}
                cell={MyCustomDropDownCell}
            />;
        }
        else if (columnConfig.isSelectable) {
            return <Column
                field={columnConfig.columnName}
                title={columnConfig.displayName}
                headerSelectionValue={
                    state.data.findIndex(dataItem => dataItem.selected === false) === -1
                }
            />;
        }
        else if (columnConfig.isDatePicker) {
            return <Column
                field={columnConfig.columnName}
                title={columnConfig.displayName}
                cell={() => <DatePicker />}
                width="200px"
            />;
        }
        else if (columnConfig.isActionButton) {
            return <Column
                field={columnConfig.columnName}
                title={columnConfig.displayName}
                cell={CommandCell}
                width="200px"
            />;
        }
        else {
            return <Column
                field={columnConfig.columnName}
                title={columnConfig.displayName}
                editable={true}
            />;
        }
    }

    if (state.data.length > 0) {
        generateColumns();
    }

    return (
        <>
            <Grid
                data={orderBy(state.data, sort)}
                sort={sort}
                sortable
                onSortChange={(e) => {
                    setSort([...e.sort]);
                }}
                onItemChange={props.handleItemChange}
                editField={editField}
                className='kendo-react-grid kendo-react-virtual-grid'
                onRowClick={props.handleRowClick}
            >
                {
                    isGridToolBarNeeded &&
                    <GridToolbar>
                        <input className='button-margin-right button-margin-top' />
                        <input className='button-margin-top' />
                        <div className='action-item-container-float-right' onClick={props.handleGridToolBarButtonClick}>
                            <span className="action-item">{gridToolBarActionName}</span>
                        </div>
                        {
                            hasEditedItemProp && (
                                <button
                                    title="Cancel current changes"
                                    className="k-button"
                                    onClick={props.handleCancelCurrentChanges}
                                >
                                    Cancel current changes
                                </button>
                            )
                        }
                    </GridToolbar>
                }
                {
                    columnDetails.map((columnDetail) => {
                        if (columnDetail.isClickable === true) {
                            return (
                                <Column
                                    key={'id'}
                                    title={columnDetail.title}
                                    cell={MyCommandCell({
                                        edit: props.handleEditClick,
                                        remove: props.handleRemoveItem,
                                        add: props.handleAddClick,
                                        discard: props.handleDiscard,
                                        update: props.handleUpdate,
                                        cancel: props.handleCancel,
                                        editField: editField,
                                        commandCellConfig: commandCellConfig,
                                        clickableField: columnDetail.field,
                                        openFormOnClick: columnDetail.openFormOnClick
                                    })}
                                    width='200px'
                                    field={columnDetail.field}
                                />
                            );
                        } else {
                            return <Column key={'id'} field={columnDetail.field} title={columnDetail.title} format={columnDetail.format} width='200px' />;
                        }
                    })
                }
                {props.isCommandCellNeeded === true &&
                    <Column
                        cell={CommandCell}
                        width="200px"
                    />}
            </Grid>
        </>

    );
};

export default EditableKendoGrid;
