
export interface Announcement {
    id?: string;
    title: string;
    linkUrl: string | undefined;
    description: string;
    publishDate: string;
    expirationDate: string;
    availableInClientWorkbench: boolean;
    availableInEmployeeWorkbench: boolean;
    availableInPortal: boolean;
    isArchived: boolean;
};

export const defaultAnnouncement: Announcement = {
    id: '',
    title: '',
    linkUrl: '',
    description: '',
    publishDate: '',
    expirationDate: '',
    availableInClientWorkbench: false,
    availableInEmployeeWorkbench: false,
    availableInPortal: false,
    isArchived: false
};
