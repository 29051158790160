import React, { ReactNode } from 'react';
import { AuthorizedUser } from './header/authorized-user.model';

export interface TrackingTokens {
    eloquaId?: string,
    sitecoreId?: string,
    userType?: string
};
export const defaultTrackingTokens: TrackingTokens = {
        eloquaId: '',
        sitecoreId: '',
        userType: ''
};

export function TrackedLink(props: { trackingTokens: TrackingTokens, user: AuthorizedUser, children: ReactNode, href: string, relationship?: string, target?: string, className?: string }) {
    return (
        <a href={getTrackedUrl(props.href, props.trackingTokens.sitecoreId, props.trackingTokens.eloquaId, props.user.products.map(x => x.ShortName))}
            rel={props.relationship}
            target={props.target}
            className={props.className}>
            {props.children}
        </a>
    );
}

function getTrackedUrl(baseUrl: string, sitecoreId?: string, eloquaId?: string, applications?: string[]): string {
    let url;
    try {
        url = new URL(baseUrl);
    } catch {
        url = new URL(`${process.env.REACT_APP_LINK_DUFF_AND_PHELPS}`);
    }
    if (sitecoreId) {
        url.searchParams.append('sitecore', sitecoreId);
    }
    if (eloquaId) {
        url.searchParams.append('eloqua', eloquaId);
    }
    if (applications) {
        const apps = applications.join(' ');
        url.searchParams.append('services', apps);
        url.searchParams.append('isPortalRedirect', 'true');
    }
    return url.toString();
}
