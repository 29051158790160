import './side-nav.component.scss';

import { Dispatcher, RootState } from '../_store/store';
import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as ChevronRightIcon } from '../_shared/_assets/icons/chevron-right.svg';
import { Nav } from 'react-bootstrap';

function SideNav(props: { children: ReactNode }) {
    const session = useSelector((state: RootState) => state.session);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dispatcher = useDispatch<Dispatcher>();

    return (
        <div className="side-nav-layout">
            <div id='side-nav-container' className={session.isNavigationOpen ? 'side-nav-toggle-container side-nav-container-expanded' : 'side-nav-toggle-container'}>
                <div className={session.isNavigationOpen ? 'side-nav-toggle expanded' : 'side-nav-toggle'}
                    onClick={dispatcher.session.toggleNavigation}
                    aria-controls="side-nav-collapse"
                    aria-expanded={session.isNavigationOpen}>
                    <ChevronRightIcon />
                </div>
                <Nav defaultActiveKey="/" className="flex-column side-nav-menu">
                    {props.children}
                </Nav>
            </div>
        </div>
    );
}

export default SideNav;