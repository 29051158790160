/* eslint-disable react-hooks/exhaustive-deps */

import './app.component.scss';
import './side-nav-list.component.scss';
import '../_shared/side-nav.component.scss';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Dispatcher, RootState } from '../_store/store';
import Header, { CorpLinks } from '../_shared/header/header.component';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MyContent from './my-content/my-content.component';
import MyProducts from './my-products/my-products.component';
import MySupport from './my-support/my-support.component';
import NotFound from './not-found/not-found.component';
import SideNav from '../_shared/side-nav.component';
import SideNavList from './side-nav-list.component';
// import TermsAndConditions from './terms-and-conditions/terms-and-conditions.component';
import { session } from '../_store/session.model';
import AdminComponent from './admin/admin-component';
import { TrackingTokens } from '../_shared/tracked-link.component';
import AnnouncementBanner from './announcement-banner';
import { ReactComponent as DuffAndPhelpsLogo } from '../_shared/_assets/images/logo-web.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { parseJwtToken } from '../_shared/header/token-user';
import { EloquaClient } from '../api/eloqua.client';
import { UserClient } from '../api/user.client';
import { PersonalizationClient } from '../api/personalization.client';
import { defaultUser } from '../_shared/header/authorized-user.model';
import { authorizedUserClient } from '../_shared/header/authorized-user.client';
import { StringConstants } from '../utilities/constants';

function App() {
    const userInfo = useSelector((state: RootState) => state.session.user);
    const isNavigationOpen = useSelector((state: RootState) => state.session.isNavigationOpen);
    const destination = useSelector((state: RootState) => state.session.userDestination);
    const dispatcher = useDispatch<Dispatcher>();
    const storage = sessionStorage;

    let contentSection = document.getElementById('section');
    if (contentSection !== null) {
        contentSection.style.marginLeft = isNavigationOpen ? '250px' : '20px';
        contentSection.style.transition = 'ease-in all 0.25s';
    }

    let corpLinks: CorpLinks = {
        duffAndPhelps: session.state.corpLinks.duffAndPhelps,
        kroll: session.state.corpLinks.kroll,
        krollBusinessServices: session.state.corpLinks.krollBusinessServices
    };
    let trackingTokensSample: TrackingTokens = {
        eloquaId: '',
        sitecoreId: '',
        userType: ''
    };
    const [trackingTokens, setTrackingTokens] = useState<TrackingTokens>(trackingTokensSample);
    const userType = (trackingTokens.userType === StringConstants.Employee) ? StringConstants.Internal : StringConstants.External;
    document.getElementsByTagName('body')[0].setAttribute('data-userType', userType);

    const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [loadComponents, setLoadComponents] = useState(false);
    const [isUserMailVerified, setIsUserEmailVerified] = useState(true);
    const portalApi = process.env.REACT_APP_PORTAL_AUDIENCE;
    const portalScope = '';
    const [seconds, setSeconds] = useState(0);
    const TOKEN_REFRESH_TIME_SECONDS = 30;

    useEffect(() => {
        if (!isNavigationOpen) {
            dispatcher.session.toggleNavigation();
        }

        let sdNavContainer = document.getElementById('side-nav-container');
        let navLinks = document.getElementsByTagName('NavLink'); // used so we could apply the classname changes to all the nav link elements unless unnecessary

        if (sdNavContainer !== null) {
            sdNavContainer.className = 'side-nav-toggle-container side-nav-container-expanded';
        }

        for (let navLink of navLinks) {
            navLink.className = 'expanded nav-link';
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (!isLoading && !isAuthenticated && !user) {
                const url = window.location.href.split('error=unauthorized');
                if (url?.length > 1) {
                    await loginWithRedirect({
                        prompt: 'login',
                        error_description: 'Please verify your email before logging in',
                    });
                } else {
                    await loginWithRedirect({
                        appState: { targetUrl: window.location }
                    });
                }
            }
        })();
    }, [isLoading]);

    useEffect(() => {
        if (isAuthenticated) getUserMetadata();
        else localStorage.setItem('token', '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    useEffect(() => {
        dispatcher.session.UpdateTrackingTokens(trackingTokens);
    }, [trackingTokens]);

    useEffect(() => {
        (async () => {
            seconds < TOKEN_REFRESH_TIME_SECONDS &&
                setTimeout(() => setSeconds(seconds + 1), 1000);
            if (seconds >= TOKEN_REFRESH_TIME_SECONDS) {
                const accessToken = await getAccessTokenSilently({
                    audience: portalApi,
                    scope: portalApi,
                });
                localStorage.setItem('token', accessToken);
                setSeconds(0);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds]);

    function getEloquaId(): any {
        try {
            const eloqua = Promise.resolve(EloquaClient.get());
            return eloqua;
        } catch {
            return '';
        }
    }
    const getUserMetadata = async () => {
        try {
            const accessToken = await getAccessTokenSilently({
                audience: portalApi,
                scope: portalScope,
            });
            localStorage.setItem('token', accessToken);
            const authenticatedUserInfo = parseJwtToken(accessToken);
            setUserInfo(authenticatedUserInfo);
            (window as any).authorizedUser = authenticatedUserInfo;
            //Set sitecore ID
            const queryKeys = 'sitecore.postauth.querykeys';
            storage.removeItem('pearl.sitecore.id');
            storage.removeItem(queryKeys);
            const params = window.location.search;
            if (params) storage.setItem(queryKeys, params);

            const querystring = storage.getItem(queryKeys);
            if (querystring) {
                storage.removeItem(queryKeys);
                const params = new URLSearchParams(querystring);
                const sitecoreUser = params.get('sitecore-user');
                if (sitecoreUser !== null) {
                    await UserClient.set(sitecoreUser);
                }
            }

            const eloquaId = await getEloquaId(); // await EloquaClient.get();
            const portalUser = await UserClient.get();
            setTrackingTokens({ ...trackingTokens, eloquaId, sitecoreId: portalUser.sitecoreId, userType: portalUser.userType });
            let userData: any = authenticatedUserInfo;
            let userMetadataKey: any = (Object.keys(authenticatedUserInfo).filter(user => user.includes('app_metadata'))).toString();
            let authorizedUserData = userData[userMetadataKey];
            let loggedUserTypeKey = (Object.keys(authenticatedUserInfo).filter(user => user.includes('userType'))).toString();
            let loggedUserType = userData[loggedUserTypeKey];
            let userEmailVerifiedKey = (Object.keys(authenticatedUserInfo).filter(user => user.includes('isEmailVerified'))).toString();
            let userEmailVerified = userData[userEmailVerifiedKey];
            loggedUserType === 'External' ? setIsUserEmailVerified(userEmailVerified) : setIsUserEmailVerified(true);

            storage.setItem('pearl.sitecore.id', portalUser.sitecoreId);
            storage.setItem('pearl.sitecore.userType', portalUser.userType);
            storage.setItem('pearl.eloqua.id', eloquaId);
            let refinedUserdata = defaultUser;
            refinedUserdata = authorizedUserData ? authorizedUserClient(authorizedUserData?.user, portalUser) : defaultUser;

            dispatcher.session.LoadUserData(refinedUserdata);

            Promise.resolve(PersonalizationClient.getInsights())
                .then((result) => {
                    dispatcher.session.getInsightsData(result.insights);
                })
                .catch((e) => {
                    console.log('error', e);
                });

        } catch (e) {
            console.log(e.message);
        } finally {
            setLoadComponents(true);
        }
    };

    async function setUserInfo(userInfo: any) {
    }

    function refreshPage() {
        window.location.reload();
    }

    return (
        <>
            {isUserMailVerified ?
                (isAuthenticated && loadComponents &&
                    <BrowserRouter>
                        {/* <TermsAndConditions /> Commenting as per marketing team new requirements as of 28/07/2021 */}
                        <Header user={userInfo} links={corpLinks} tokens={trackingTokens} />
                        <main>
                            <SideNav>
                                <SideNavList />
                            </SideNav>
                            <section id='section'>
                                <AnnouncementBanner user={userInfo} links={corpLinks} tokens={trackingTokens} />
                                <Switch>
                                    <Route exact path='/'>
                                        <Redirect to={destination} />
                                    </Route>
                                    <Route exact path='/my-products' render={(props) => (
                                        <MyProducts {...props} trackingTokens={trackingTokens} />
                                    )} />
                                    <Route exact path='/my-content' render={(props) => (
                                        <MyContent {...props} trackingTokens={trackingTokens} />
                                    )} />
                                    <Route exact path='/my-support' component={MySupport} />
                                    <Route exact path='/admin' component={AdminComponent} />
                                    <Route component={NotFound} />
                                </Switch>
                            </section>
                        </main>
                        {/* <Footer /> */}
                    </BrowserRouter>)
                :
                <>
                    <div className='mail-verification'>
                        <div className='logo'>
                            <DuffAndPhelpsLogo className="logo" />
                        </div>
                        <article>
                            <div className='headerText'>
                                Check Your Email
                            </div>
                            <div className='message'>
                                Please check your inbox for an email verification message. Once verified you will be able to log into the portal.
                            </div>
                            <div className='refreshPage'>
                                Have you verified your email? If yes, please <span className='refreshPage__click' onClick={refreshPage}>click here </span>to continue.
                            </div>
                        </article>
                    </div>
                </>
            }
        </>
    );
}

export default App;