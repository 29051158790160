import { GridCell } from '@progress/kendo-react-grid';
import React from 'react';

type StatusIconDictionary = {
    [isInvitationApproved: string]: string;
}

const statusIconMap: StatusIconDictionary = {
    'true': 'green-circle',
    'false': 'orange-circle'
};
const field = 'isInvitationApproved';

export function StatusIndicatorCell() {
    return class extends GridCell {
        render() {
            const { dataItem } = this.props;
            const stringStatus = dataItem[field].toString();
            return (
                <td>
                    <span className={statusIconMap[stringStatus]}></span>
                </td>
            );
        }
    };
};

