import './_shared/_assets/scss/main.scss';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import App from './app/app.component';
import React from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@uifabric/icons';
import { initializeStore } from './_store/store';
import { getConfig } from './config';
import history from './utilities/history';
import { Auth0Provider } from '@auth0/auth0-react';
import { defaultUser } from './_shared/header/authorized-user.model';
import { Provider } from 'react-redux';
import { defaultTrackingTokens } from './_shared/tracked-link.component';

async function startup() {

  const onRedirectCallback = (appState: any): void => {
    history.push(
      appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
    );
  };
  const config = getConfig();
  const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    redirectUri: window.location.origin,
    audience: config.audience,
    useRefreshTokens: true,
    onRedirectCallback,
  };
  const user = defaultUser;

  initializeIcons();
  ReactDOM.render(
    <Auth0Provider {...providerConfig}>
      <Provider store={initializeStore(user, defaultTrackingTokens, [])}>
        <App />
      </Provider>,
    </Auth0Provider>, document.getElementById('react-root')
  );

}

startup();