export function formatDateTimeToMMDDYYYY(inputDate: string | undefined, withTime?: boolean, isUTC?: boolean): string {
    if (!inputDate) {
        return '';
    }
    const janTZOffset = new Date(2020, 1, 1).getTimezoneOffset();
    const juneTZOffset = new Date(2020, 6, 1).getTimezoneOffset();
    const currentTZOffset = new Date().getTimezoneOffset();
    // simpler way to determine if our local timezone is having a DST right now.
    const isDSTCurrentTZ = juneTZOffset !== janTZOffset && currentTZOffset === juneTZOffset;
    // Assuming the offset to be of EST and DST ON.
    const isDSTSourceTZ = true; // TODO: Need a discussion on this with Duff to return the actual server timezone offset
    const dateInput = new Date(inputDate);
    // converting time to millisecond units and then to the destination timezone 
    const date = new Date(dateInput.getTime() + (60000 * (300 - (isDSTSourceTZ ? 60 : 0) - (dateInput.getTimezoneOffset() - (isDSTCurrentTZ ? 60 : 0)))));
    const month = isUTC ? date.getUTCMonth() : date.getMonth();
    const dateValue = isUTC ? date.getUTCDate() : date.getDate();
    const fullYear = isUTC ? date.getUTCFullYear() : date.getFullYear();
    const hours = isUTC ? date.getUTCHours() : date.getHours();
    const minutes = isUTC ? date.getUTCMinutes() : date.getMinutes();
    const seconds = isUTC ? date.getUTCSeconds() : date.getSeconds();
    const outputDate = ((month > 8)
        ? (month + 1)
        : ('0' + (month + 1))) + '/' + ((dateValue > 9)
            ? dateValue
            : ('0' + dateValue)) + '/' + fullYear;
    if (withTime) {
        return `${outputDate} ${hours}:${minutes}:${seconds} ${isUTC === true ? '(UTC)' : ''}`;
    } else {
        return `${outputDate} ${isUTC === true ? '(UTC)' : ''}`;
    }
}

export function isNullOrEmpty(str?: string) {
    return str == null || str === '';
}


export function isNotNullOrWhiteSpaceOrUndefined(str: string | undefined): boolean {
    if (str && str !== null && str !== undefined && str.trim().length > 0) {
        return true;
    }
    return false;
}

export function isNotNullOrEmptyOrUndefinedDate(str: string | undefined): boolean {
    // TODO - This should be gone once we clean out the bad data on the database
    const invalidDate = '0001-01-01T00:00:00';

    if (str && str !== null && str !== undefined && str.trim().length > 0 && str !== invalidDate) {
        return true;
    }
    return false;
}

export function formatDateTimeToUtcMMDDYYYY(inputDate: string | Date | undefined): string {
    if (inputDate === undefined || inputDate === null)
        return '';
    if (!inputDate) {
        return '';
    }
    if (inputDate && inputDate.toString().length === 0)
        return '';

    let inputDateAsDate = new Date();

    if (inputDate instanceof Date)
        inputDateAsDate = inputDate;
    else {
        inputDateAsDate = new Date(inputDate);
    }
    var inputDateAsUtcDate = new Date(Date.UTC(inputDateAsDate.getFullYear(), inputDateAsDate.getMonth(), inputDateAsDate.getDate(), 0, 0, 0));

    return inputDateAsUtcDate.toLocaleDateString('en-us'); //MMDDYYYY
}

export const onFormatDate = (date: Date = new Date()): string => {
    return (date.getMonth() + 1) + '/' + (date.getDate()) + '/' + (date.getFullYear());
};