// TODO: Could be a class rather
export interface AuthorizedUser {
    Id: string;
    primaryEmail: string;
    firstName: string;
    lastName: string;
    Initials: string;
    Country: string;
    Company: string;
    products: Application[];
    Role: string;
}

export interface Application {
    productName: string;
    ShortName: string;
    loginUrl: string;
    productId: string;
}

export const defaultUser: AuthorizedUser = {  
    Id:'',
    firstName:'',
    lastName:'',
    primaryEmail:'',
    products:[
    ],
    Role:'',
    Initials: '',
    Country: '',
    Company: '',
 };