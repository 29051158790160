
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import SingleModal from '../../common/modal/single-modal';

interface SuccessModalProps {
    component: React.ComponentClass | React.FunctionComponent;
    isShowing: boolean;
    onHide: () => void | undefined;
    modalHeaderText: string;
}

const SuccessModal = (props: SuccessModalProps) => {
    const { component, isShowing, onHide, modalHeaderText } = props;
    return (
        <SingleModal
            Component={component}
            className='modal-dialog-message-box'
            show={isShowing}
            onHide={onHide}
            modalHeaderText={modalHeaderText}
        />
    );
};

export default SuccessModal;