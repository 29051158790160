import { IDatePickerStyles, IDropdownStyles, IPanelStyles, IStackItemStyles, IStackProps, IStackStyles, IStackTokens, IStyle, IStyleFunctionOrObject, ITextFieldStyles, loadTheme, mergeStyleSets } from 'office-ui-fabric-react';

// from create task
export const stackTokensBottom = { childrenGap: 45, padding: 80 };
export const stackTokensBottomSpacer = { childrenGap: 45, padding: 30 };
export const stackTokensLabel = { childrenGap: 1, padding: 10 };

export const stackStylesBottom: Partial<IStackStyles> = { root: { paddingTop: 80 } };
export const stackStylesBottomSpacer: Partial<IStackStyles> = { root: { paddingTop: 30 } };
//


export const stackTokens = { childrenGap: 10, padding: 10 };
export const columnPropsLeft: Partial<IStackProps> = {
    tokens: { childrenGap: 5 },
    styles: { root: { width: 650, marginRight: 50 } },
};
export const columnPropsRight: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 250 } },
};
export const columnPropsRightWithPadding: Partial<IStackProps> = {
    tokens: { childrenGap: 15, padding: 10 },
    styles: { root: { width: 250 } },
};
export const columnPropsLeftBottom: Partial<IStackProps> = {
    tokens: { childrenGap: 5, padding: 5 },
    styles: { root: { width: 700 } },
};

export const columnPropsRightBottom: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 450 } },
};

export const labelStyle: Partial<IStyleFunctionOrObject<any, any>> = {
    root: {
        fontFamily: '"Open Sans", sans-serif;',
        fontWeight: '400',
        fontSize: '16px',
        letterSpacing: '-0.18px'
    },
};

const dropdownItemStyle: Partial<IStyle> = {
    fontSize: '14px',
    letterSpacing: '-0.2px',
    fontWeight: '400',
    borderBottom: '0.5px solid #b5bbc0',
    height: '35px',
    paddingLeft: '16px',
    selectors: {
        ':hover, :hover:focus, :focus': {
            color: 'white',
            backgroundColor: '#36b4ea',
        }
    }
};

export const panelStyles: Partial<IPanelStyles> = {
    subComponentStyles: {
        closeButton: {
            root: {
                color: '#eb3023'
            },
            rootFocused: {
                outline: 'none'
            },
            icon: {
                fontSize: 24
            }
        }
    }
};

export const formTextFieldStyles: Partial<ITextFieldStyles> = {
    field: {
        fontSize: '1rem',
        letterSpacing: '-.18px',
    },
    fieldGroup: {
        height: '35px',
        border: '1px solid #b5bbc0',
        padding: '5px 10px'
    },
    subComponentStyles: {
        label: labelStyle
    }
};

export const formTextFieldExpandableStyles: Partial<ITextFieldStyles> = {
    field: {
        border: '1px solid #b5bbc0',
    },
    fieldGroup: {
        border: '0px'
    }
};

export const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
        selectors: {
            '& .ms-Dropdown-title--hasError': {
                borderColor: 'rgb(164, 38, 44)'
            }
        }
    },
    subComponentStyles: {
        label: labelStyle,
        panel: {},
        multiSelectItem: {}
    },
    title: {
        fontSize: '1rem',
        letterSpacing: '-.18px',
        height: '35px',
        border: '1px solid #b5bbc0',
        paddingLeft: '16px'
    },
    dropdownOptionText: {
        paddingBottom: '2px',
    },
    dropdownItem: dropdownItemStyle,
    dropdownItemSelected: dropdownItemStyle,
    dropdownItemDisabled: dropdownItemStyle,
    dropdownItemSelectedAndDisabled: dropdownItemStyle
};

export const DatePickerPanelTextField: Partial<IStyle> = {
    height: '50px',
    padding: '5px 10px',
    border: '1px solid #ced4da',
    selectors: {
        '& input, & input::placeholder': {
            fontFamily: 'OpenSans-Light',
            fontSize: '0.96rem',
            letterSpacing: '0.-18px',
        },
    }
};

export const DatePickerPearlBaseStyling: Partial<IDatePickerStyles> = {
    root: {
        width: '100%'
    },
    textField: {
        selectors: {
            '& .ms-TextField-fieldGroup': {
                ...DatePickerPanelTextField
            },
            '& .ms-TextField-wrapper label': {
                fontSize: '0.96rem',
                fontFamily: 'OpenSans-Light',
                fontWeight: 'normal',
                padding: 0
            }
        }
    },
    callout: {
        selectors: {
            '& .ms-DatePicker-goToday': {
                right: '14px'
            }
        }
    },
    icon: {
        paddingTop: '10px'
    }
};

export const DatePickerPearlPanelStyling: Partial<IDatePickerStyles> = {
    ...DatePickerPearlBaseStyling,
    textField: {
        selectors: {
            '& .ms-TextField-fieldGroup': {
                ...DatePickerPanelTextField,
                height: '35px'
            }
        }
    },
    icon: {
        paddingTop: '3px'
    },
};

export const controlClass = mergeStyleSets({
    control: {
        margin: '0 0 15px 0',
        maxWidth: '300px',
    },
});

loadTheme({
    defaultFontStyle: {
        fontFamily: '"OpenSans-Light",BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;'
    },
    palette: {

    }
});

export const itemAlignmentStackProps: Partial<IStackProps> = {
    tokens: {
        childrenGap: 50
    },
    styles: {
        root: { width: 650, height: 80 }
    }
};

export const buttonAlignmentStackProperties: Partial<IStackProps> = {
    styles: {
        root: { width: 650, height: 80, display: 'inline-flex', justifyContent: 'flex-end' }
    }
};

export const stackItemWordButtonStyles: IStackItemStyles = { root: { width: 170, paddingTop: 20, textAlign: 'right', display: 'flex', justifyContent: 'center', alignItems: 'center' } };
export const stackItemButtonStyles: IStackItemStyles = { root: { width: 170, paddingTop: 20, textAlign: 'right', display: 'inline-flex', justifyContent: 'flex-end', alignItems: 'center' } };

export const itemAlignmentsStackTokens: IStackTokens = { childrenGap: 50 };
export const stackItemStyles: IStackItemStyles = { root: { width: '100%' } };
export const itemAlignmentsStackTokensBottom: IStackTokens = { childrenGap: 5 };
export const stackItemStylesRight: IStackItemStyles = { root: { width: 150, paddingTop: 20, textAlign: 'right' } };
export const stackItemStylesBottom: IStackItemStyles = { root: { width: 150, paddingTop: 20 } };
export const stackItemStylesSave: IStackItemStyles = { root: { width: 200, textAlign: 'center' } };
export const stackItemSubSectionStyles: IStackItemStyles = { root: { paddingBottom: 20 } };