/* eslint-disable react-hooks/exhaustive-deps */

import './my-account.component.scss';

import { Checkbox, PrimaryButton, Spinner } from 'office-ui-fabric-react/lib';
import React, { useState } from 'react';

import { AuthorizedUser } from './../authorized-user.model';
import { EloquaSubscriptionsClient } from '../../../api/eloqua-subscriptions.client';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

/*
    Condensing the local state variables to one object state to minimise the number of re-renders,
    as each one of the state variable change would compare the dom and the virtual dom trees, to determine if a re-render is required.
*/
type MyAccountLocalState = {
    isAnnouncementsChecked: boolean,
    isEventsChecked: boolean,
    isReportsChecked: boolean,
    isRemovalChecked: boolean
};

function MyAccount(props: { user: AuthorizedUser }) {
    const [isFetchingSubs, setIsFetchingSubs] = useState(false);
    const [myAccountLocalState, setMyAccountLocalState] = useState<MyAccountLocalState>({
        isAnnouncementsChecked: true,
        isEventsChecked: true,
        isReportsChecked: true,
        isRemovalChecked: false
    });

    useEffect(() => {
        setIsFetchingSubs(true);
        EloquaSubscriptionsClient
            .get()
            .then((responseObject) => {
                setMyAccountLocalState({
                    ...myAccountLocalState,
                    isAnnouncementsChecked: responseObject.announcements,
                    isEventsChecked: responseObject.events,
                    isReportsChecked: responseObject.reports,
                    isRemovalChecked: !responseObject.isOptedIn
                });
            }).finally(() => setIsFetchingSubs(false));
    }, []);

    const uncheckAll = React.useCallback((event: any, checked?: boolean): void => {
        let myAccountLocalStateCopy = { ...myAccountLocalState };
        myAccountLocalStateCopy.isRemovalChecked = !!checked;
        if (checked) {
            myAccountLocalStateCopy = {
                ...myAccountLocalStateCopy,
                isAnnouncementsChecked: false,
                isEventsChecked: false,
                isReportsChecked: false
            };
        }
        setMyAccountLocalState({ ...myAccountLocalStateCopy });
    }, []);
    const { logout } = useAuth0();

    const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

    function onAnnouncementsChange(e: any, checked?: boolean) {
        let myAccountLocalStateCopy = { ...myAccountLocalState };
        myAccountLocalStateCopy.isAnnouncementsChecked = !!checked;

        if (checked) {
            myAccountLocalStateCopy.isRemovalChecked = false;
        }
        if (myAccountLocalStateCopy.isAnnouncementsChecked
            && !myAccountLocalStateCopy.isEventsChecked
            && !myAccountLocalStateCopy.isReportsChecked) {
            myAccountLocalStateCopy.isRemovalChecked = true;
        }
        setMyAccountLocalState({ ...myAccountLocalStateCopy });
    }

    function onEventsChange(e: any, checked?: boolean) {
        let myAccountLocalStateCopy = { ...myAccountLocalState };
        myAccountLocalStateCopy.isEventsChecked = !!checked;
        if (checked) {
            myAccountLocalStateCopy.isRemovalChecked = false;
        }
        if (!myAccountLocalStateCopy.isAnnouncementsChecked
            && myAccountLocalStateCopy.isEventsChecked
            && !myAccountLocalStateCopy.isReportsChecked) {
            myAccountLocalStateCopy.isRemovalChecked = true;
        }
        setMyAccountLocalState({ ...myAccountLocalStateCopy });
    }

    function onReportsChange(e: any, checked?: boolean) {
        let myAccountLocalStateCopy = { ...myAccountLocalState };
        myAccountLocalStateCopy.isReportsChecked = !!checked;
        if (checked) {
            myAccountLocalStateCopy.isRemovalChecked = false;
        }
        if (!myAccountLocalStateCopy.isAnnouncementsChecked
            && !myAccountLocalStateCopy.isEventsChecked
            && myAccountLocalStateCopy.isReportsChecked) {
            myAccountLocalStateCopy.isRemovalChecked = true;
        }
        setMyAccountLocalState({ ...myAccountLocalStateCopy });
    }

    function updatePreferences(e: any, checked?: boolean) {
        setIsFetchingSubs(true);
        EloquaSubscriptionsClient
            .set({
                announcements: myAccountLocalState.isAnnouncementsChecked,
                events: myAccountLocalState.isEventsChecked,
                reports: myAccountLocalState.isReportsChecked,
                unsubscribe: myAccountLocalState.isRemovalChecked
            }).then((responseObject) => {
                setMyAccountLocalState({
                    ...myAccountLocalState,
                    isAnnouncementsChecked: responseObject.announcements,
                    isEventsChecked: responseObject.events,
                    isReportsChecked: responseObject.reports,
                    isRemovalChecked: !responseObject.isOptedIn
                });
            }).finally(() => setIsFetchingSubs(false));
    }

    function spinner() {
        return <Spinner label='Updating Email Subscriptions...'></Spinner>;
    }

    function subscriptions() {
        return (
            <>
                <h2>Email Subscriptions</h2>
                <Checkbox label="Announcements" checked={myAccountLocalState.isAnnouncementsChecked} onChange={onAnnouncementsChange} />
                <Checkbox label="Events/Webcasts" checked={myAccountLocalState.isEventsChecked} onChange={onEventsChange} />
                <Checkbox label="Reports" checked={myAccountLocalState.isReportsChecked} onChange={onReportsChange} />
                <div>
                    If you would like to be removed from all distribution lists, please check the box below:
            </div>
                <div>
                    <Checkbox label="Remove me from all distribution lists" checked={myAccountLocalState.isRemovalChecked} onChange={uncheckAll} />
                </div>
                {isFetchingSubs ? spinner() : <PrimaryButton text="Update My Preferences" onClick={updatePreferences} disabled={isFetchingSubs} />}
            </>
        );
    }

    function emailsubscriptions() {
        var className = isFetchingSubs ? 'email-options not-implemented' : 'email-options';
        return (
            <div className={className}>
                {subscriptions()}
            </div>
        );
    }

    function userActions() {
        if (props.user.primaryEmail.toLowerCase().indexOf('@duffandphelps') > -1) {
            return;
        }

        return (
            <div className="user-actions">
                <div className="external-link" onClick={() => {}}>
                    Edit Account Details
                </div>
                <div className="external-link" onClick={() => {}}>
                    Change Password
                </div>
            </div>
        );
    }

    return (
        <div className="my-account">
            <h1>My Account</h1>
            <div className="user-icon">{props.user.Initials}</div>
            <div className="signout external-link" onClick={logoutWithRedirect}>
                Sign Out
            </div>
            <div className="user-details">
                <div className="detail-item">
                    <div className="detail-name">Name</div>
                    <div className="detail-entry">{props.user.firstName} {props.user.lastName}</div>
                </div>
                <div className="detail-item">
                    <div className="detail-name">Company</div>
                    <div className="detail-entry">{props.user.Company}</div>
                </div>
                <div className="detail-item">
                    <div className="detail-name">Email</div>
                    <div className="detail-entry">{props.user.primaryEmail}</div>
                </div>
                <div className="detail-item">
                    <div className="detail-name">Country</div>
                    <div className="detail-entry">{props.user?.Country}</div>
                </div>
                {userActions()}
            </div>
            {emailsubscriptions()}
        </div>
    );
}

export default MyAccount;