/* eslint-disable react-hooks/exhaustive-deps */

import { ColumnDetail } from '../../../_shared/grids/grid.model';
import { IStackItemStyles, IStackStyles, MessageBar, MessageBarButton, MessageBarType, Stack } from 'office-ui-fabric-react';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import React, { useEffect, useState } from 'react';
import { ServiceNowCase, ServiceNowCaseCommentRequest } from '../../../api/models/service-now-models';
import { formatDateTimeToMMDDYYYY, isNullOrEmpty } from '../../../utilities/utility-functions';

import { AuthorizedUser } from '../../../_shared/header/authorized-user.model';
import { Button } from 'react-bootstrap';
import EditableKendoGrid from '../../../_shared/grids/editable-kendo-grid';
import { ServiceNowClient } from '../../../api/service-now-client';
import { Spinner } from 'office-ui-fabric-react/lib/';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { ResponseMessageConfig } from '../support.models';

interface IncomingProps {
    isReloadRequired: boolean;
    authorizedUser: AuthorizedUser;
    communicateReloadCompleted: ((isReloadComplete: boolean) => void);
}

const ServiceNowCaseList = (props: IncomingProps) => {
    const defaultItems: any[] = [];
    const theme = getTheme();

    const columnList: ColumnDetail[] = [
        { field: 'title', title: 'NAME', isClickable: true, isStatusIndicator: false, openFormOnClick: false },
        { field: 'state', title: 'STATUS', isClickable: false, isStatusIndicator: false, openFormOnClick: false },
        { field: 'openedOn', title: 'OPENED ON', isClickable: false, isStatusIndicator: false, openFormOnClick: false },
        { field: 'closedOn', title: 'RESOLVED ON', isClickable: false, isStatusIndicator: false, openFormOnClick: false }
    ];

    const [state, setState] = useState({
        sortedItems: defaultItems,
        isFetching: false,
        columns: columnList,
        user: props.authorizedUser
    });

    const [isExternalReloadTriggered, setIsExternalReloadTriggered] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [caseObject, setCaseObject] = useState<ServiceNowCase>({} as ServiceNowCase);
    const [newComment, setNewComment] = useState<string>('');
    const [messagesString, setMessagesString] = useState('');

    const [responseMessageConfig, setResponseMessageConfig] = useState<ResponseMessageConfig>({
        message: '',
        show: false,
        isError: false
    });
    const stackTokens = { childrenGap: 15, padding: 10 };
    const stackTokensBottomSpacer = { childrenGap: 45, padding: 30 };
    const stackStylesBottomSpacer: Partial<IStackStyles> = { root: { width: 300, paddingTop: 30 } };
    const stackTokensBottom = { childrenGap: 45, padding: 80 };
    const stackStylesBottom: Partial<IStackStyles> = { root: { width: 300, paddingTop: 80 } };
    const stackItemStylesSave: IStackItemStyles = { root: { width: 200, textAlign: 'center' } };
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        reloadServiceNowCases();
    }, []);

    function reloadServiceNowCases(): void {
        setState({ ...state, isFetching: true });
        ServiceNowClient
            .get()
            .then(responseObject => {
                if (responseMessageConfig.show === false) {
                    setResponseMessageConfig({
                        isError: false,
                        message: '',
                        show: false
                    });
                }
                setState({ ...state, sortedItems: responseObject, isFetching: false });
                props.communicateReloadCompleted(true);
            }).catch(error => {
                setResponseMessageConfig({
                    isError: true,
                    message: 'Could not load inquiries.',
                    show: true
                });
                setState({ ...state, isFetching: false });
            });
    }

    if (props.isReloadRequired && !isExternalReloadTriggered) {
        reloadServiceNowCases();
        setIsExternalReloadTriggered(true);
    } else if (!props.isReloadRequired && isExternalReloadTriggered) {
        setIsExternalReloadTriggered(false);
    }

    function handleEditRowItemClick(eventData: any) {
        setIsOpen(true);
        setIsFormValid(false);
        setCaseObject(eventData as ServiceNowCase);
        setMessagesString(formMessagesContent(eventData as ServiceNowCase));
    }

    function validateCase(message: string) {
        setIsFormValid(isNullOrEmpty(message.trim()) === false);
    }

    function handleDescriptionChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        const messageTextValue = newValue !== undefined ? newValue.trim() : '';
        validateCase(messageTextValue);
        setNewComment(messageTextValue);
    }

    async function handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const serviceNowCommentRequest: ServiceNowCaseCommentRequest = {
            comment: `${state.user.primaryEmail} - ${newComment}`
        };
        try {
            await ServiceNowClient.appendCommentToServiceNowCase(caseObject.caseId, serviceNowCommentRequest);
            setResponseMessageConfig({
                isError: false,
                message: 'The Inquiry has been updated with your comment, successfully.',
                show: true
            });
            reloadServiceNowCases();
            setIsOpen(false);
        } catch (error) {
            setResponseMessageConfig({
                isError: true,
                message: error,
                show: true
            });
        }

        setIsOpen(false);
    }

    function formMessagesContent(caseObj: ServiceNowCase): string {
        if (caseObj) {
            let i = (caseObj.commentAndWorkNotes && caseObj.commentAndWorkNotes.length > 0)
                ? caseObj.commentAndWorkNotes.length
                : 0;
            if (i === 0) {
                return '';
            }
            let prevAuthor = caseObj.commentAndWorkNotes[0].commentedBy;
            let skipFirstIteration = true;
            let inquirySubmittedBy = '';
            inquirySubmittedBy = `${state.user.firstName} ${state.user.lastName}`;
            const messages = caseObj.commentAndWorkNotes.map(commentObj => {
                if (skipFirstIteration === true) {
                    skipFirstIteration = false;
                    prevAuthor = commentObj.commentedBy;
                    return `\n${commentObj.isWorkNote ? '\tService Desk' : inquirySubmittedBy} - \n${commentObj.comment} \n${formatDateTimeToMMDDYYYY(commentObj.commentDateTime, true, false)} \n`;
                } else {
                    if (prevAuthor !== commentObj.commentedBy) {
                        prevAuthor = commentObj.commentedBy;
                        return `\n${commentObj.isWorkNote ? '\tService Desk' : inquirySubmittedBy} - \n${commentObj.isWorkNote ? '\t' : ''}${commentObj.comment} \n${commentObj.isWorkNote ? '\t' : ''}${formatDateTimeToMMDDYYYY(commentObj.commentDateTime, true, false)} \n`;
                    } else {
                        return `\n${commentObj.comment} \n${formatDateTimeToMMDDYYYY(commentObj.commentDateTime, true, false)} \n`;
                    }
                }
            });
            let messageString = messages.join(' ');
            return messageString;
        }
        return '';
    }

    return (
        <>
            {responseMessageConfig.show &&
                <MessageBar
                    actions={
                        <div>
                            <MessageBarButton onClick={() => { setResponseMessageConfig({ ...responseMessageConfig, show: false }); }}>Ok</MessageBarButton>
                        </div>
                    }
                    messageBarType={responseMessageConfig.isError === true ? MessageBarType.error : MessageBarType.success}
                    isMultiline={false}
                    styles={{
                        root: {
                            width: 800
                        }
                    }}
                >
                    {responseMessageConfig.message}
                </MessageBar>}
            {!state.isFetching &&
                state.sortedItems &&
                state.sortedItems.length > 0
                ?
                (<EditableKendoGrid
                    incomingGridDataProps={state.sortedItems.map((item) => ({
                        ...item,
                        openedOn: formatDateTimeToMMDDYYYY(item.openedOn),
                        closedOn: formatDateTimeToMMDDYYYY(item.closedOn),
                    }))}
                    isGridToolBarNeeded={false}
                    handleRowClick={() => { }}
                    gridItemName={'Inquiry'}
                    columnDetails={state.columns}
                    hasEditedItemProp={false}
                    commandCellConfig={{
                        addNewIcon: false,
                        editIcon: false,
                        addSubIcon: false,
                        clickableCellElement: true
                    }}
                    isCommandCellNeeded={false}
                    handleEditClick={handleEditRowItemClick}
                    defaultSortByColumn={{
                        field: 'openedOn',
                        dir: 'desc'
                    }} />)
                : (!state.isFetching && <p>No records to display</p>)
            }
            {state.isFetching && <Spinner
                label={`Loading Inquiries for ${state.user.firstName} ${state.user.lastName}`} />}

            <Panel
                isOpen={isOpen}
                onDismiss={() => { setIsOpen(false); }}
                type={PanelType.smallFixedFar}
                closeButtonAriaLabel="Close"
                headerText="Update Inquiry"
                styles={{
                    closeButton: { color: theme.palette.red },
                    headerText: {
                        fontFamily: 'OpenSans-Light',
                        fontWeight: 'normal'
                    }
                }}
            >
                <Stack tokens={stackTokens}>
                    <TextField label="Inquiry Title *" defaultValue={caseObject.title} disabled={true} />
                    <TextField label="Comments" multiline autoAdjustHeight disabled={true} defaultValue={messagesString} />
                    <TextField label="Add Comment *" multiline autoAdjustHeight onChange={handleDescriptionChange} />
                </Stack>
                <Stack tokens={stackTokensBottomSpacer} styles={stackStylesBottomSpacer}>

                </Stack>
                <Stack horizontalAlign={'center'} tokens={stackTokensBottom} styles={stackStylesBottom}>
                    <Stack.Item styles={stackItemStylesSave}>
                        <div className="position-bootstrap-padding">
                            <Button className='pearl-modal-primary-btn' onClick={handleSubmit} disabled={!isFormValid}>
                                <span>Submit</span>
                            </Button>
                        </div>
                    </Stack.Item>
                </Stack>
            </Panel>
        </>
    );
};
export default ServiceNowCaseList;