import { ApiClient } from './api-client';
import { Bookmark } from './models/bookmark.models';

export class BookmarkClient extends ApiClient {
    static baseUrl = `${process.env.REACT_APP_PORTAL_API}/v1`;

    static async get(): Promise<Bookmark[]> {
        return await this.fetch<Bookmark[]>(`${this.baseUrl}/bookmark`);
    }

    static async write(bookmarks: Bookmark[]): Promise<any> {
        this.trackAnalytics('Add Bookmarks', bookmarks);
        return await this.post<any>(`${this.baseUrl}/bookmark`, bookmarks);
    }

    static async remove(bookmarkIds: string[]): Promise<any> {
        this.trackAnalytics('Remove Bookmarks', bookmarkIds);
        return await this.delete<any>(`${this.baseUrl}/bookmark`, bookmarkIds);
    }
}
