/* eslint-disable react-hooks/exhaustive-deps */

import * as React from 'react';

import { ColumnDetail } from '../../_shared/grids/grid.model';

import { AuthorizedUser } from '../../_shared/header/authorized-user.model';
import EditableKendoGrid from '../../_shared/grids/editable-kendo-grid';
import { RootState } from '../../_store/store';
import { Spinner } from 'office-ui-fabric-react/lib/';
import { SupportDocumentsClient } from '../../api/support-documents.client';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export interface ISupportDocument {
    thumbnail: string | null;
    key: string;
    name: string;
    description: string;
    url: string;
    category: string;
    subcategory: string | '';
}

function SupportDocumentList() {
    const authorizedUser: AuthorizedUser = useSelector((rootState: RootState) => rootState.session.user);
    const defaultItems: ISupportDocument[] = [];

    const columnList: ColumnDetail[] = [
        { field: 'category', title: 'CATEGORY', isClickable: false, openFormOnClick: false, isStatusIndicator: false },
        { field: 'subcategory', title: 'SUB-CATEGORY', isClickable: false, openFormOnClick: false, isStatusIndicator: false },
        { field: 'name', title: 'NAME', isClickable: true, openFormOnClick: true, isStatusIndicator: false },
        { field: 'description', title: 'DESCRIPTION', openFormOnClick: false, isClickable: false, isStatusIndicator: false }
    ];

    const [state, setState] = React.useState({
        sortedItems: defaultItems,
        isFetching: false,
        columns: columnList,
        user: authorizedUser
    });

    let subscribedApplications = ['Portal'];
    if (state.user && state.user.products) {
        state.user.products.map((a) => subscribedApplications.push(a.productName));
    }
    // Workbench, Navigator, Form ADV, ...
    const filterFunction = function (el: ISupportDocument) {
        return (el.category === 'Application' && subscribedApplications.indexOf(el.subcategory) >= 0) ||
            (el.category === 'General');
    };
    const sortFunction = function (el1: ISupportDocument, el2: ISupportDocument) {
        if (el1.category !== el2.category) {
            if (el1.category > el2.category) return 1;
            else return -1;
        }
        else if (el1.subcategory !== el2.subcategory) {
            if (el1.subcategory > el2.subcategory) return 1;
            else return -1;
        }
        else if (el1.name !== el2.name) {
            if (el1.name > el2.name) return 1;
            else return -1;
        }
        else if (el1.description !== el2.description) {
            if (el1.description > el2.description) return 1;
            else return -1;
        }
        else return 0;
    };

    useEffect(() => {
        (async () => {
            setState({ ...state, isFetching: true });
            try {
                let documents = await SupportDocumentsClient.get();
                let filtered = documents.filter(filterFunction);
                let sortedAndFiltered = filtered.sort(sortFunction);
                setState({ ...state, sortedItems: sortedAndFiltered, isFetching: false });
            } catch {
                setState({ ...state, isFetching: false });
            }
        })();
    }, []);

    return (
        <>
            {!state.isFetching &&
                state.sortedItems &&
                state.sortedItems.length > 0
                ?
                (<EditableKendoGrid
                    incomingGridDataProps={state.sortedItems}
                    isGridToolBarNeeded={false}
                    handleRowClick={() => { }}
                    gridItemName={'Inquiry'}
                    columnDetails={state.columns}
                    hasEditedItemProp={false}
                    commandCellConfig={{
                        addNewIcon: false,
                        editIcon: false,
                        addSubIcon: false,
                        clickableCellElement: true
                    }}
                    isCommandCellNeeded={false}
                    handleEditClick={() => { }}
                    defaultSortByColumn={{
                        field: 'category',
                        dir: 'asc'
                    }}
                />)
                :
                (!state.isFetching && <p>No records to display</p>)

            }
            {state.isFetching && <Spinner
                label={`Loading Support Documents for ${state.user.firstName} ${state.user.lastName}`} />}
        </>
    );
}

export default SupportDocumentList;