import './my-notifications.component.scss';

import BellNotification, { BellNotificationData } from './bell-notification.component';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import React, { useState } from 'react';

import ErrorBoundary from '../../error-boundary-component';
import { Icon } from '@fluentui/react/lib/Icon';
import { NotificationsClient } from '../../../api/notification.client';
import PearlNotification from '../../../api/models/notification.models';
import { useEffect } from 'react';

const NOTIFICATION_REFRESH_TIME_SECONDS = 30;
const MESSAGE_NO_NOTIFICATIONS = 'No Notifications';
const MESSAGE_NOTIFICATION_NOT_RETRIEVED = 'Notifiations Could Not Be Retrieved';

function MyNotifications(props: { notifications: PearlNotification[], refresh: () => void }) {
    return (
        <div className="my-notifications">
            <h1>Notifications ({props.notifications.length})</h1>
            <NotificationsGrid notifications={props.notifications} refresh={props.refresh}></NotificationsGrid>
        </div>
    );
}

function BellIcon(props: { class: string, onClick: () => void, notificationsCount: number }) {
    return (
        <> <Icon iconName="Ringer"
            className={props.class}
            onClick={props.onClick} />
            { props.notificationsCount > 0 && <span className="notification-indicator-badge" onClick={props.onClick}>{props.notificationsCount}</span>}
        </>
    );
}

function NotificationBox(props: { notification: PearlNotification, refresh: () => void }) {
    
    const dismissNotification = (id: string) => {
        NotificationsClient
            .dismiss([id])
            .then(() =>
                props.refresh()
            )
            .catch(error => {
                console.log(error);
            }).finally(() => {} );
    };

    try {
        const payload: BellNotificationData = JSON.parse(props.notification.messageDetails);
        return BellNotification({
            notification: {
                notificationId: props.notification.notificationId,
                Subject: payload.Subject,
                Message: payload.Message,
                OriginatingUser: payload.OriginatingUser,
                updatedOn: new Date(props.notification.updatedOn) || new Date(),
                Link: payload.Link
            },
            dismiss: dismissNotification,
        });
    } catch {
        return BellNotification({
            notification: {
                notificationId: '0',
                Subject: 'ERROR',
                Message: (props.notification.messageDetails || ''),
                OriginatingUser: {
                    FirstName: 'Unknown',
                    LastName: 'Unknown'
                },
                updatedOn: new Date(),
                Link: 'http://www.duffandphelps.com'
            },
            dismiss: (id: string) => { },
        });
    }
}


function NotificationsGrid(props: { notifications: PearlNotification[] | undefined, refresh: () => void }) {


    if (!props.notifications?.length) return <div>{MESSAGE_NO_NOTIFICATIONS}</div>;

    let notificationBoxes = props.notifications.map((notification) => {
        return (
            <NotificationBox key={notification.notificationId} notification={notification} refresh={props.refresh}></NotificationBox>
        );
    });

    return (
        <ErrorBoundary errorMessage={MESSAGE_NOTIFICATION_NOT_RETRIEVED}>
            <div>{notificationBoxes}</div>
        </ErrorBoundary>);
}


export default function NotificationsBell() {
    const [seconds, setSeconds] = useState(0);

    const [notifications, setNotifications] = useState<PearlNotification[]>();

    const refreshNotifications = () => {
        NotificationsClient
            .getBells()
            .then((responseObject) => {
                setNotifications(responseObject);
            }).catch(error => {
                console.log(error);
            }).finally();
    };

    useEffect(() => {
        refreshNotifications();
    }, []);

    useEffect(() => {
        seconds < NOTIFICATION_REFRESH_TIME_SECONDS &&
            setTimeout(() => setSeconds(seconds + 1), 1000);
        if (seconds >= NOTIFICATION_REFRESH_TIME_SECONDS) {
            refreshNotifications();
            setSeconds(0);
        }
    }, [notifications, seconds]);

    const [isPanelShowing, setIsPanelShowing] = useState(false);
    const panelStyle = {
        subComponentStyles: {
            closeButton: {
                root: {
                    color: '#eb3023',
                },
                icon: {
                    fontSize: 24
                }
            },
        }
    };
    return (
        <>
            <BellIcon
                class={isPanelShowing ? 'icon notifications-icon icon-active' : 'icon notifications-icon'}
                onClick={() => { setIsPanelShowing(true); }}
                notificationsCount={notifications?.length || 0} />
            <Panel
                isLightDismiss
                isOpen={isPanelShowing}
                onDismiss={() => setIsPanelShowing(false)}
                closeButtonAriaLabel="Close"
                type={PanelType.custom}
                customWidth="600px"
                styles={panelStyle}>
                <MyNotifications notifications={notifications || []} refresh={refreshNotifications} />
            </Panel>
        </>
    );
}
