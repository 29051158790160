

type HttpHeaders = { Accept?: string, Authorization?: string, 'Content-Type'?: string }
type HttpAction<T> = (headers: HttpHeaders) => Promise<T>;

export abstract class ApiClient {
    protected static trackAnalytics(eventName: string, data: any) {
        const env = (window as any);
        env.dataLayer = env.dataLayer || [];
        env.dataLayer.push({
            'CTAName': eventName,
            'pageName': window.location.pathname,
            'pageTitle': document.title,
            'data': data
        });
    }

    protected static fetch<T>(path: string): Promise<T> {
        const action = (headers: any) => fetch(`${path}`, { headers });
        return this.doHttpAction(action);
    }

    protected static post<T>(path: string, payload: any): Promise<T> {
        const action = (headers: any) => fetch(`${path}`, { headers, method: 'POST', body: JSON.stringify(payload) });
        return this.doHttpAction(action);
    }

    protected static put<T>(path: string, payload: any): Promise<T> {
        const action = (headers: any) => fetch(`${path}`, { headers, method: 'PUT', body: JSON.stringify(payload) });
        return this.doHttpAction(action);
    }

    protected static delete<T>(path: string, payload: any): Promise<T> {
        const action = (headers: any) => fetch(`${path}`, { headers, method: 'DELETE', body: JSON.stringify(payload) });
        return this.doHttpAction(action);
    }

    private static async doHttpAction<T>(httpAction: HttpAction<Response>): Promise<T> {
        const headers = await this.getHeaders();
        try {
            const response = await httpAction(headers);
            const responseObject = {} as T;
            const content = await response.text();

            if (response.ok) {
                return content === '' ? responseObject : JSON.parse(content);
            } else {
                throw this.createError(response.status, content);
            }
        } catch (error) {
            throw error;
        }
    }

    private static async getHeaders(): Promise<HttpHeaders> {
        const headers: HttpHeaders = {};
        headers.Accept = 'application/json';
        headers['Content-Type'] = 'application/json';

        const token = localStorage.getItem('token'); //TODO auth0
        headers.Authorization = `Bearer ${token}`;
        return headers;
    }

    // TODO: How to handle errors?
    private static createError(status: number, error: string): Error {
        if (status === 400) {
            console.log('Server indicated the request was badly formed.');
        }

        if (status === 401) {
            console.log('User was not Authorized to make that request.');
        }

        if (status === 500) {
            console.log(error);
        }

        return new Error(error);
    }
}