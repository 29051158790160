import { GridCell } from '@progress/kendo-react-grid';
import { Link } from 'office-ui-fabric-react/lib/';
import { ReactComponent as PlusCircle } from '../_assets/icons/plus-circle.svg';
import { ReactComponent as ProjectDiagram } from '../_assets/icons/project-diagram.svg';
import React from 'react';

export function MyCommandCell(props: any) {
    const { handleEditRender, add, remove, update, discard, cancel, editField, commandCellConfig, openFormOnClick, clickableField, handleIconRender, columnDetail } = props;

    return class extends GridCell {
        state = {
            showModal: false,
        };

        render() {
            const { dataItem } = this.props;
            const inEdit = dataItem[editField];

            const isNewItem = dataItem.EngagementId === undefined;

            return inEdit ? (
                <td className='k-command-cell'>
                    <button className='k-button k-grid-save-command'
                        onClick={() => (isNewItem ? add(dataItem) : update(dataItem))}
                    >
                        {isNewItem ? 'Add' : 'Update'}
                    </button>
                    <button className='k-button k-grid-cancel-command'
                        onClick={() => (isNewItem ? discard(dataItem) : cancel(dataItem))}
                    >
                        {isNewItem ? 'Discard' : 'Cancel'}
                    </button>
                </td>
            ) : (
                    <td>
                        {commandCellConfig && commandCellConfig.addNewIcon && (
                            <PlusCircle className='my-command-cell-icon'
                                // eslint-disable-next-line no-restricted-globals
                                onClick={() => confirm('Confirm addition?')}
                            />
                        )}
                        {commandCellConfig && commandCellConfig.addSubIcon && (
                            <ProjectDiagram className='my-command-cell-icon'
                                // eslint-disable-next-line no-restricted-globals
                                onClick={() => confirm('Confirm sub-task addition?')}
                            />
                        )}
                        {commandCellConfig && commandCellConfig.iconRender && (
                            handleIconRender(dataItem, columnDetail)
                        )}
                        {commandCellConfig && commandCellConfig.editIcon && (
                            handleEditRender(dataItem)
                        )}
                        {commandCellConfig && commandCellConfig.deleteIcon && (
                            remove(dataItem)
                        )}
                        {commandCellConfig && commandCellConfig.clickableCellElement
                            && (
                                <Link
                                    target='_blank'
                                    onClick={() => openFormOnClick
                                        ? window.open(dataItem.url)
                                        : handleEditRender(dataItem)}> {dataItem[clickableField]}
                                </Link >
                            )}

                    </td>
                );
        }
    };
}
