import { Modal, ModalProps } from 'react-bootstrap';

import React from 'react';

interface IModalComponentProp extends ModalProps {
    Component: React.ComponentClass | React.FunctionComponent;
    className?: string;
    modalHeaderText: string;
    enforceFocus?: boolean
}

const SingleModal = (props: IModalComponentProp) => {
    const { Component } = props;

    return (
        <div className={props.className}>
            <Modal {...props}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.modalHeaderText}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Component />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SingleModal;