import { DropDownList } from '@progress/kendo-react-dropdowns';
import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';

interface DropDownProps extends GridCellProps {
    dropDownData: any;
}

export const DropDownCell = (props: DropDownProps) => {
    const { dropDownData } = props;

    const value = props.dataItem[props.field !== undefined ? props.field : ''];

    return (
        <td>
            <DropDownList
                style={{ width: '100px' }}
                value={value}
                data={dropDownData}
                textField="name"
            />
        </td>
    );
};