/* eslint-disable react-hooks/exhaustive-deps */

import { TrackedLink, TrackingTokens } from '../../_shared/tracked-link.component';

import { ReactComponent as ChevronRightIcon } from '../../_shared/_assets/icons/chevron-right.svg';
import { PersonalizationClient } from '../../api/personalization.client';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../_store/store';

export interface IPersonalization {
    link: string;
    title: string;
    description: string;
}

export interface IPersonalizationResult {
    responseListItems: IPersonalization[],
    url: string;
}

function OtherServices(props: { trackingTokens: TrackingTokens }) {
    const user = useSelector((state: RootState) => state.session.user);
    const defaultItems: IPersonalizationResult = { responseListItems: new Array<IPersonalization>(), url: '' };
    const [state, setState] = React.useState({
        items: defaultItems,
        isFetching: false
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, isFetching: true });
            try {
                let personalization = await PersonalizationClient.get();
                setState({ ...state, items: personalization, isFetching: false });
            } catch {
                setState({ ...state, isFetching: false });
            }
        })();
    }, []);

    const areas = state.items.responseListItems.map((x, index, arry) => {
        return (
            <div className="card-wrapper" key={index}>
                <TrackedLink href={x.link} trackingTokens={props.trackingTokens} user={user}>
                    <div className="card hover-box" key={x.title}>
                        <h2>{x.title}</h2>
                    </div>
                </TrackedLink>
                {((index + 1) === arry.length) &&
                    <TrackedLink href={state.items.url} trackingTokens={props.trackingTokens} user={user}>
                        <div className='display-more explore'>
                            <h2>Explore all Kroll Services</h2>
                            <ChevronRightIcon className="chevron" />
                        </div>
                    </TrackedLink>
                }
            </div>

        );
    });

    return (
        <>
            <h1>Areas where we can help</h1>

            <div className="cards">
                {areas}
            </div>
        </>
    );
}

export default OtherServices;