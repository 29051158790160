import { TrackingTokens, TrackedLink } from '../tracked-link.component';

import { AuthorizedUser } from './authorized-user.model';
import { CorpLinks } from './header.component';
import React from 'react';

export function AppList(props: { user: AuthorizedUser, links: CorpLinks, tokens: TrackingTokens }) {
    const portalUrl = `${process.env.REACT_APP_PORTAL_UI}`;
    const currentApplication = `${process.env.REACT_APP_NAME}`;

    const portalApplication = {
        loginUrl: portalUrl,
        productName: 'Portal',
        ShortName: 'Portal'
    };

    const appList = [portalApplication, ...props.user.products];

    const applicationLinks =
        appList
            .filter((f) => f.productName !== currentApplication)
            .map((x) => <TrackedLink href={x.loginUrl} key={x.productName} target={x.ShortName} trackingTokens={props.tokens} user={props.user}>{x.productName}</TrackedLink>);

    return <div className="apps-list">
        {applicationLinks}
        {applicationLinks.length !== 0 ? <hr /> : null}
        <TrackedLink href={props.links.kroll} trackingTokens={props.tokens} user={props.user}>Kroll</TrackedLink>
        <TrackedLink href={props.links.duffAndPhelps} trackingTokens={props.tokens} user={props.user}>Duff &amp; Phelps</TrackedLink>
        <TrackedLink href={props.links.krollBusinessServices} trackingTokens={props.tokens} user={props.user}>Kroll Business Services</TrackedLink>
        <hr />
        <TrackedLink href={`${portalUrl}/my-content`} trackingTokens={props.tokens} user={props.user}>My Content</TrackedLink>
    </div>;
}