/* eslint-disable react-hooks/exhaustive-deps */

import './my-content.component.scss';

import { IStackItemStyles, Stack } from 'office-ui-fabric-react/lib/Stack';
import React, { useEffect, useState } from 'react';
import { TrackedLink, TrackingTokens } from '../../_shared/tracked-link.component';

import { AuthorizedUser } from '../../_shared/header/authorized-user.model';
import { Bookmark } from '../../api/models/bookmark.models';
import { BookmarkClient } from '../../api/bookmark.client';
import { ReactComponent as ChevronRightIcon } from '../../_shared/_assets/icons/chevron-right.svg';
import ContentCard from './content-card.component';
import { Icon } from '@fluentui/react/lib/Icon';
import { RootState } from '../../_store/store';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { useSelector } from 'react-redux';

// TODO: Refactor so Insights and Bookmarks are not separate entities
function MyContent(props: { trackingTokens: TrackingTokens }) {
    const user: AuthorizedUser = useSelector((state: RootState) => state.session.user);
    const insights: Bookmark[] = useSelector((state: RootState) => state.session.insights);

    const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    useEffect(() => { updateBookmarks(); }, []);

    function updateBookmarks() {
        BookmarkClient.get()
            .then((data) => {
                setBookmarks(data);
                setInitialLoadComplete(true);
            });
    }

    let hasBookmarks = bookmarks.length > 0;
    let bookmarksHtml = BookmarkUtility.getBookmarks(bookmarks, updateBookmarks, props.trackingTokens, user);
    let bookmarksCaseStudyHtml = BookmarkUtility.getCaseStudyBookmarks(bookmarks, updateBookmarks, props.trackingTokens, user);
    let insightsHtml = BookmarkUtility.getInsights(insights, bookmarks, updateBookmarks, props.trackingTokens, user);
    let insightsCaseStudyHtml = BookmarkUtility.getCaseStudyInsights(insights, bookmarks, updateBookmarks, props.trackingTokens, user);

    const insightsLink = `${process.env.REACT_APP_LINK_DUFF_AND_PHELPS}/insights`;

    return (
        initialLoadComplete ?
            <div className="my-content">
                {hasBookmarks ?
                    <div className="top">
                        <h1 className="my-content-header">My Content</h1>
                    </div>
                    :
                    <div className="top">
                        <h1>Welcome {user.firstName}, your content space is ready!</h1>
                        <h2>Start bookmarking content to begin optimizing your Kroll experience</h2>
                    </div>
                }
                <div className="bottom flex-auto">
                    {hasBookmarks &&
                        <><h1>Bookmarks</h1>
                            <div className="bookmarks">
                                {bookmarksHtml}
                            </div>
                            <div className="bookmarks">
                                {bookmarksCaseStudyHtml}
                            </div>
                        </>
                    }
                    <h1>My Recommended Insights</h1>
                    <div className="bookmarks">
                        {insightsHtml}
                    </div>
                    <div className="bookmarks">
                        {insightsCaseStudyHtml}
                    </div>
                    <TrackedLink href={insightsLink} className='display-more explore' trackingTokens={props.trackingTokens} user={user}>
                        <h2>View More Insights</h2>
                        <ChevronRightIcon className="chevron" />
                    </TrackedLink>
                </div>
            </div> : null
    );
}

// TODO: Passing the updateBookmarks function to this code is awkward - is there a better way?
export class BookmarkUtility {
    private static insightsLink = `${process.env.REACT_APP_LINK_DUFF_AND_PHELPS}/insights`;

    public static getBookmarks(bookmarks: Bookmark[], updateBookmarks: Function, trackingTokens: TrackingTokens, user: AuthorizedUser) {
        const sortedBookmarks = bookmarks.sort((a, b) => {
            if (!a.updatedOn || !b.updatedOn) { return 0; }
            return new Date(b.updatedOn).valueOf() - new Date(a.updatedOn).valueOf();
        });

        const allElements: any = sortedBookmarks.filter(x => x.type !== 'Case Study').map((bookmark, index) => {
            if (bookmark.type !== 'Event') {
                return ContentCard({
                    content: {
                        id: bookmark.id,
                        type: bookmark.type,
                        category: bookmark.category,
                        linkUrl: bookmark.linkUrl,
                        imageUrl: bookmark.imageUrl,
                        title: bookmark.title,
                        profileIndustry: bookmark.profileIndustry,
                        profileSiteInterest: bookmark.profileSiteInterest,
                        profileService: bookmark.profileService,
                        date: bookmark.date,
                        caseStudyImage1: bookmark.caseStudyImage1,
                        caseStudyImage2: bookmark.caseStudyImage2,
                        caseStudyImage3: bookmark.caseStudyImage3,
                        caseStudyDescription1: bookmark.caseStudyDescription1,
                        caseStudyDescription2: bookmark.caseStudyDescription2,
                        caseStudyDescription3: bookmark.caseStudyDescription3
                    },
                    detail: (<p>{bookmark.description}</p>),
                    action: this.getDeleteTooltip(bookmark, index, updateBookmarks),
                    trackingTokens: trackingTokens,
                    user: user
                });
            } else {
                return ContentCard({
                    content: {
                        id: bookmark.id,
                        type: bookmark.type,
                        category: bookmark.category,
                        linkUrl: bookmark.linkUrl,
                        imageUrl: bookmark.imageUrl,
                        title: bookmark.title,
                        profileIndustry: bookmark.profileIndustry,
                        profileSiteInterest: bookmark.profileSiteInterest,
                        profileService: bookmark.profileService,
                        date: bookmark.date,
                        caseStudyImage1: bookmark.caseStudyImage1,
                        caseStudyImage2: bookmark.caseStudyImage2,
                        caseStudyImage3: bookmark.caseStudyImage3,
                        caseStudyDescription1: bookmark.caseStudyDescription1,
                        caseStudyDescription2: bookmark.caseStudyDescription2,
                        caseStudyDescription3: bookmark.caseStudyDescription3
                    },
                    detail: this.getEventDetail(bookmark),
                    action: this.getDeleteTooltip(bookmark, index, updateBookmarks),
                    trackingTokens: trackingTokens,
                    user: user
                });
            }
        });

        return allElements;
    }

    public static getCaseStudyBookmarks(bookmarks: Bookmark[], updateBookmarks: Function, trackingTokens: TrackingTokens, user: AuthorizedUser) {
        const sortedBookmarks = bookmarks.sort((a, b) => {
            if (!a.updatedOn || !b.updatedOn) { return 0; }
            return new Date(b.updatedOn).valueOf() - new Date(a.updatedOn).valueOf();
        });

        const allElements: any = sortedBookmarks.filter(x => x.type === 'Case Study').map((bookmark, index) => {
            return ContentCard({
                content: {
                    id: bookmark.id,
                    type: bookmark.type,
                    linkUrl: bookmark.linkUrl,
                    imageUrl: bookmark.imageUrl,
                    title: bookmark.title,
                    category: bookmark.category,
                    profileIndustry: bookmark.profileIndustry,
                    profileSiteInterest: bookmark.profileSiteInterest,
                    profileService: bookmark.profileService,
                    date: bookmark.date,
                    caseStudyImage1: bookmark.caseStudyImage1,
                    caseStudyImage2: bookmark.caseStudyImage2,
                    caseStudyImage3: bookmark.caseStudyImage3,
                    caseStudyDescription1: bookmark.caseStudyDescription1,
                    caseStudyDescription2: bookmark.caseStudyDescription2,
                    caseStudyDescription3: bookmark.caseStudyDescription3
                },
                detail: (<p>{bookmark.description}</p>),
                action: this.getDeleteTooltip(bookmark, index, updateBookmarks),
                trackingTokens: trackingTokens,
                user: user
            });
        });

        return allElements;
    }

    public static getInsights(insights: Bookmark[], bookmarks: Bookmark[], updateBookmarks: Function, trackingTokens: TrackingTokens, user: AuthorizedUser) {
        let applicableInsights = insights.filter(x => bookmarks.filter(z => z.id === x.id).length === 0).filter(x => x.type !== 'Case Study');

        return applicableInsights.map((insight, index, array) => {
            let cardData = {
                id: insight.id,
                type: insight.type,
                linkUrl: insight.linkUrl,
                imageUrl: insight.imageUrl,
                title: insight.title,
                category: insight.category,
                profileIndustry: insight.profileIndustry,
                profileSiteInterest: insight.profileSiteInterest,
                profileService: insight.profileService,
                date: insight.date,
                caseStudyImage1: insight.caseStudyImage1,
                caseStudyImage2: insight.caseStudyImage2,
                caseStudyImage3: insight.caseStudyImage3,
                caseStudyDescription1: insight.caseStudyDescription1,
                caseStudyDescription2: insight.caseStudyDescription2,
                caseStudyDescription3: insight.caseStudyDescription3
            };

            return ContentCard({
                content: cardData,
                detail: <p>{insight.description}</p>,
                action: this.getBookmarkTooltip(insight, index, updateBookmarks),
                trackingTokens: trackingTokens,
                user
            });
        });
    }

    public static getCaseStudyInsights(insights: Bookmark[], bookmarks: Bookmark[], updateBookmarks: Function, trackingTokens: TrackingTokens, user: AuthorizedUser) {
        let applicableInsights = insights.filter(x => bookmarks.filter(z => z.id === x.id).length === 0).filter(x => x.type === 'Case Study');

        return applicableInsights.map((insight, index, array) => {
            let cardData = {
                id: insight.id,
                type: insight.type,
                linkUrl: insight.linkUrl,
                imageUrl: insight.imageUrl,
                title: insight.title,
                category: insight.category,
                profileIndustry: insight.profileIndustry,
                profileSiteInterest: insight.profileSiteInterest,
                profileService: insight.profileService,
                date: insight.date,
                caseStudyImage1: insight.caseStudyImage1,
                caseStudyImage2: insight.caseStudyImage2,
                caseStudyImage3: insight.caseStudyImage3,
                caseStudyDescription1: insight.caseStudyDescription1,
                caseStudyDescription2: insight.caseStudyDescription2,
                caseStudyDescription3: insight.caseStudyDescription3
            };

            return ContentCard({
                content: cardData,
                detail: <p>{insight.description}</p>,
                action: this.getBookmarkTooltip(insight, index, updateBookmarks),
                trackingTokens: trackingTokens,
                user
            });
        });
    }

    public static getDeleteTooltip(bookmark: Bookmark, index: number, action: Function) {
        let behavior = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.stopPropagation();
            await BookmarkClient.remove([bookmark.id]);
            await action();
        };

        return (
            <TooltipHost content="Remove" id={'bookmark' + index} calloutProps={{ target: '#bookmark' + index }}>
                <Icon iconName="Delete" id={'bookmark' + index} className="icon" onClick={behavior} />
            </TooltipHost>
        );
    }

    public static getBookmarkTooltip(insight: Bookmark, index: number, updateBookmarks: Function) {
        let behavior = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.stopPropagation();
            try {
                await BookmarkClient.write([insight]);
                await updateBookmarks();
                return true;
            }
            catch (error) {
                console.log(error);
            }
            return false;
        };

        return (
            <TooltipHost content="Add to Bookmarks" id={'insight' + index} calloutProps={{ target: '#insight' + index }}>
                <Icon iconName="SingleBookmark" id={'insight' + index} className="icon" onClick={behavior} />
            </TooltipHost>
        );
    }

    public static getEventDetail(bookmark: Bookmark) {
        let htokens = {
            childrenGap: 20,
        };
        const stackItemStyles: IStackItemStyles = {
            root: {
                paddingTop: 8
            },

        };
        const stackPStyle = {
            marginTop: '2px',
            marginBottom: '12px'
        };

        return (
            <>
                <Stack>
                    <Stack horizontal tokens={htokens} styles={stackItemStyles}>
                        <Icon iconName="Calendar" className='icon-calendar' onClick={() => { }} />
                        <p style={stackPStyle}>{bookmark.date}</p>
                    </Stack>
                    <Stack horizontal tokens={htokens}>
                        <Icon iconName="POISolid" className='icon-location' onClick={() => { }} />
                        <p style={stackPStyle}>{bookmark.location}</p>
                    </Stack>
                </Stack>
            </>
        );
    }
}

export default MyContent;