

import React, { useEffect, useState } from 'react';
import { TrackedLink, TrackingTokens } from '../_shared/tracked-link.component';
import { NotificationsClient } from '../api/notification.client';
import PearlNotification from '../api/models/notification.models';
import { Icon } from '@fluentui/react/lib/Icon';
import { Row, Col } from 'react-bootstrap';
import { AuthorizedUser } from '../_shared/header/authorized-user.model';
import './announcement-banner.scss';
import { isNotNullOrWhiteSpaceOrUndefined } from '../utilities/utility-functions';

export interface CorpLinks {
    duffAndPhelps: string,
    kroll: string,
    krollBusinessServices: string
}
function AnnouncementBanner(props: { user: AuthorizedUser, links: CorpLinks, tokens: TrackingTokens }) {
    const {user, tokens} = props;
    const [showAnnouncement, setShowAnnouncement] = useState(false);
    const [activeAnnouncement, setActiveAnnouncement] = useState<PearlNotification[]>();
    const ANNOUNCEMENT_REFRESH_TIME_SECONDS = 30;
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        refreshActiveAnnouncement();
    }, []);

    useEffect(() => {
        if(activeAnnouncement && activeAnnouncement.length > 0){
            setShowAnnouncement(true);
        }
    }, [activeAnnouncement]);

    useEffect(() => {
        seconds < ANNOUNCEMENT_REFRESH_TIME_SECONDS &&
            setTimeout(() => setSeconds(seconds + 1), 1000);
        if (seconds >= ANNOUNCEMENT_REFRESH_TIME_SECONDS) {
            refreshActiveAnnouncement();
            setSeconds(0);
        }
    }, [activeAnnouncement, seconds]);

    function refreshActiveAnnouncement(){
        NotificationsClient
            .getActiveAnnouncement()
            .then((responseObject) => {
                setActiveAnnouncement(responseObject);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
        });
    }

    const handleAnnouncementclose = () => { 
        const cAncmt = (activeAnnouncement && activeAnnouncement.length>0) ? activeAnnouncement[0].notificationId : '';
        NotificationsClient
            .dismiss([cAncmt])
            .then((responseObject) => {
                setShowAnnouncement(false);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
        });
    };
    return(
        <>
            {(activeAnnouncement && activeAnnouncement.length > 0) && 
                <div className='announcement-banner'>
                <Row className= {showAnnouncement ? 'conditionalOpen' : 'conditionalClose'}>
                    <Col xs={11}>
                    <TrackedLink href={JSON.parse(activeAnnouncement[0].messageDetails).Link} trackingTokens={tokens} className='ancmt-text' user={user} target='_blank'>
                        <span className='float-left'>Announcements: {JSON.parse(activeAnnouncement[0].messageDetails).Message}</span>
                        <span className='float-right'>{isNotNullOrWhiteSpaceOrUndefined(JSON.parse(activeAnnouncement[0].messageDetails).Link) ? 'Read More  >' : ''}</span>
                    </TrackedLink>
                </Col>
                <Col xs={1} className='announcement-close'>
                    <Icon iconName="ChromeClose" className="icon" onClick={handleAnnouncementclose}/>
                </Col>
                </Row>
                </div>
            }
      </>
    );
}

export default AnnouncementBanner;