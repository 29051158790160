import { ServiceNowCase, ServiceNowCaseCommentRequest, ServiceNowCaseRequest } from './models/service-now-models';

import { ApiClient } from './api-client';

export class ServiceNowClient extends ApiClient {
    static baseUrl = `${process.env.REACT_APP_PORTAL_API}/v1`;

    static async get(): Promise<Array<ServiceNowCase>> {
        return await this.fetch<Array<ServiceNowCase>>(`${this.baseUrl}/ServiceNow/Case`);
    }

    static async createServiceNowCase(serviceNowCase: ServiceNowCaseRequest): Promise<any> {
        this.trackAnalytics('Begin Service Now Case', serviceNowCase);
        return await this.post<ServiceNowCaseRequest>(`${this.baseUrl}/ServiceNow/Case`, serviceNowCase);
    }

    static async appendCommentToServiceNowCase(caseId: string, comment: ServiceNowCaseCommentRequest): Promise<any> {
        this.trackAnalytics('Comment on Service Now Case', comment);
        return await this.post<ServiceNowCaseCommentRequest>(`${this.baseUrl}/ServiceNow/Case/${caseId}/Comment`, comment);
    }
}