/* eslint-disable react-hooks/exhaustive-deps */
import * as styles from '../fluentui/fluentui-pearl-styling';
import { Button, Col, Row } from 'react-bootstrap';

import {
  DatePicker,
  Stack,
  TextField,
  Checkbox
} from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { Announcement, defaultAnnouncement } from '../../api/models/announcement.models';
import { isNotNullOrWhiteSpaceOrUndefined, isNotNullOrEmptyOrUndefinedDate,formatDateTimeToUtcMMDDYYYY, onFormatDate } from '../../utilities/utility-functions';
import { AnnouncementClient } from './../../api/announcement.client';
import { ResponseMessageConfig } from '../../interfaces/types';
import { MessageBar, MessageBarButton, MessageBarType } from 'office-ui-fabric-react';


type CreateAnnouncementProps = {
  createAnnouncementProcess: () => void | undefined;
  handleCancelClick: () => void | undefined;
};
const CreateAnnouncement = (props: CreateAnnouncementProps) => {
  const { createAnnouncementProcess, handleCancelClick } = props;
  const [announcementObject, setAnnouncementObject] = useState({...defaultAnnouncement, linkUrl: undefined} as Announcement);
  const [numCharsRem, setNumCharsRem] = useState(180);
  const [isFormValid, setIsFormValid] = useState(true);
  const [publishDate, setPublishDate] = React.useState<Date | null | undefined>(null);
  const [expirationDate, setUnpublishDate] = React.useState<Date | null | undefined>(null);
  const [createAnnouncementText, setCreateAnnouncementText] = useState('Create Announcement');
  const [responseMessageConfig, setResponseMessageConfig] = useState<ResponseMessageConfig>({
      message: '',
      show: false,
      isError: false,
      isMultiLine: true
  });

  useEffect(() => {
    validateAnnouncement(announcementObject);
  }, [announcementObject]);

  function handleNameChange(event: any): void {
      const AnnouncementText = event.target.value;
      const NewAnnouncement = { ...announcementObject, description: AnnouncementText };

      setNumCharsRem(180-AnnouncementText.length);
      setAnnouncementObject({ ...NewAnnouncement });
  }
  function handleURLChange(event: any): void {
      const AnnouncementURL = isNotNullOrEmptyOrUndefinedDate(event.target.value) ? event.target.value : undefined;
      const NewAnnouncement = { ...announcementObject, linkUrl: AnnouncementURL };

      setAnnouncementObject({ ...NewAnnouncement });
  }
  function validateAnnouncement(announcementObject: Announcement) {
      if((isNotNullOrWhiteSpaceOrUndefined(announcementObject?.description) && announcementObject?.description.length <= 180) && 
      isNotNullOrEmptyOrUndefinedDate(announcementObject.publishDate) &&
      isNotNullOrEmptyOrUndefinedDate(announcementObject.expirationDate) && 
      (announcementObject.availableInClientWorkbench || announcementObject.availableInEmployeeWorkbench || announcementObject.availableInPortal)) {
              setIsFormValid(true);
          } 
      else{
            setIsFormValid(false);
      }
  }

  function onSelectPublishDate(date: Date | null | undefined): void {
      setPublishDate(date);
      const NewAnnouncement = { ...announcementObject, publishDate: formatDateTimeToUtcMMDDYYYY(date ? date.toString() : '') };
      setAnnouncementObject({ ...NewAnnouncement });
  }
  function onSelectUnpublishDate(date: Date | null | undefined): void {
      setUnpublishDate(date);
      const NewAnnouncement = { ...announcementObject, expirationDate: formatDateTimeToUtcMMDDYYYY(date ? date.toString() : '') };
      setAnnouncementObject({ ...NewAnnouncement });
  }

  async function handleCreateAnnouncement(){
    let hasError = false;
    setIsFormValid(false);
    setCreateAnnouncementText('Creating Announcement');
    try{
      await AnnouncementClient.create(announcementObject);
    }catch(err){
      const message = err.message ? err.message : err;
      hasError = true;
      setResponseMessageConfig({ message: message, show: true, isError: true, isMultiLine: true });
    }
    finally{
      if(!hasError){
        handleCancelClick();
        createAnnouncementProcess();
      }
      setIsFormValid(true);
      setCreateAnnouncementText('Create Announcement');
    }
  }
  
  return (
    <>
        <div id="announcement-modal">
          {responseMessageConfig.show &&
                <MessageBar
                    actions={
                        <div>
                            <MessageBarButton onClick={() => {
                                setResponseMessageConfig({ ...responseMessageConfig, show: false });
                            }}>OK</MessageBarButton>
                        </div>
                    }
                    messageBarType={
                        responseMessageConfig.isError
                            ? MessageBarType.error
                            : MessageBarType.success}
                    isMultiline={false}
                >
                    {responseMessageConfig.message}
                </MessageBar>
              }
          <Stack>
            <Stack.Item className='announcement-text'>
              <TextField
                placeholder='Enter Announcement Text'
                styles={styles.formTextFieldStyles}
                onChange={handleNameChange}
                max={180}
                value={announcementObject.description}
                multiline autoAdjustHeight
                onRenderLabel={ (p) => <Row id={p?.label} className="ms-ChoiceFieldLabel"><Col className="announcement-left-label" xs={6}>Announcement Text *</Col><Col xs={6} className="announcement-right-label">{numCharsRem} Characters Remaining</Col></Row>}
              />
            </Stack.Item>
            <Stack.Item>
              <TextField
                label='URL for More Info'
                placeholder='http://www.domain.com'
                styles={styles.formTextFieldStyles}
                onChange={handleURLChange}
                value={announcementObject.linkUrl}
              />
            </Stack.Item>
            <Stack.Item styles={styles.stackItemStyles}>
                <DatePicker
                    className={styles.controlClass.control}
                    label="Publish Date"
                    isRequired={true}
                    allowTextInput={false}
                    placeholder="MM/DD/YYYY"
                    ariaLabel="MM/DD/YYYY"
                    styles={styles.DatePickerPearlPanelStyling}
                    value={publishDate!}
                    formatDate={onFormatDate}
                    onSelectDate={onSelectPublishDate}
                />
            </Stack.Item>
            <Stack.Item styles={styles.stackItemStyles}>
                <DatePicker
                    className={styles.controlClass.control}
                    label="Unpublish Date"
                    isRequired={true}
                    allowTextInput={false}
                    placeholder="MM/DD/YYYY"
                    ariaLabel="MM/DD/YYYY"
                    styles={styles.DatePickerPearlPanelStyling}
                    value={expirationDate!}
                    formatDate={onFormatDate}
                    onSelectDate={onSelectUnpublishDate}
                    minDate={announcementObject.publishDate ? new Date(announcementObject.publishDate) : undefined}
                />
            </Stack.Item>
            <Stack.Item styles={styles.stackItemStyles}>
                <div className="ms-Label custom-label">Platforms (Select Up to 3) *</div>
                <Checkbox label='Client Workbench' onChange={(e, checked) => setAnnouncementObject({...announcementObject, availableInClientWorkbench: checked ? true : false})}/>
                <Checkbox label='Employee Workbench' onChange={(e, checked) => setAnnouncementObject({...announcementObject, availableInEmployeeWorkbench: checked ? true : false})}/>
                <Checkbox label='Portal' onChange={(e, checked) => setAnnouncementObject({...announcementObject, availableInPortal: checked ? true : false})}/>
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={styles.itemAlignmentsStackTokensBottom} className="save-btns">
            <Row className="justify-flex-end">
              <Button className='pearl-modal-secondary-btn addhyperlinkblue' onClick={() => handleCancelClick()}>
                <span>Cancel</span>
              </Button>
              <Button className='pearl-modal-primary-btn' disabled={!isFormValid} onClick={handleCreateAnnouncement}>
                <span>{createAnnouncementText}</span>
              </Button>
            </Row>
          </Stack>
        </div>
    </>
  );
};

export default CreateAnnouncement;