import { AuthorizedUser } from './authorized-user.model';

export function authorizedUserClient(authorizedUser: any, portalUserProfile: any): AuthorizedUser {
    return {
        Id: authorizedUser.Id,
        primaryEmail: authorizedUser.primaryEmail,
        firstName: authorizedUser.firstName,
        lastName: authorizedUser.lastName,
        Company: authorizedUser.userProfile.company,
        Initials: `${authorizedUser.firstName.toUpperCase().slice(0, 1)}${authorizedUser.lastName.toUpperCase().slice(0, 1)}`,
        Country: authorizedUser.userProfile.country,
        products: authorizedUser.products,
        Role: portalUserProfile.role
    };
}
