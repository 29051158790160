import './my-products.component.scss';

import { TrackedLink, TrackingTokens } from '../../_shared/tracked-link.component';

import { AuthorizedUser } from '../../_shared/header/authorized-user.model';
import OtherServices from './other-services.component';
import React from 'react';
import { RootState } from '../../_store/store';
import { useSelector } from 'react-redux';
import { StringConstants } from '../../utilities/constants';

function MyProducts(props: { trackingTokens: TrackingTokens }) {
    const user = useSelector((state: RootState) => state.session.user as AuthorizedUser);
    const userHasApplications = user && user.products && user.products.length > 0;

    const products = user.products.filter(product => product.productId !== StringConstants.PortalProductId).map((x) => {
        return (
            <div className="card-wrapper" key={x.productName}>
                <TrackedLink href={x.loginUrl} trackingTokens={props.trackingTokens} user={user}>
                    <div className="card hover-box" key={x.productName}>
                        <h2>{x.productName}</h2>
                    </div>
                </TrackedLink>
            </div >
        );
    });


    return (
        <div className="my-products">
            <div className="top">

                {userHasApplications ?
                    <div>
                        <h1>
                            My Products
                    </h1>
                    </div>
                    :
                    <div>
                        <h1>
                            Welcome {user.firstName}, your product space is ready!
                    </h1>
                        <h2>
                            Start a free trial, or complete a purchase to begin optimizing your Kroll experience.
                    </h2>
                    </div>
                }

                <div className="cards">
                    {products}
                </div>
            </div>

            <div className="bottom card-section flex-auto">
                <OtherServices trackingTokens={props.trackingTokens} />
            </div>
        </div>
    );
}

export default MyProducts;