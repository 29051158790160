import { ApiClient } from './api-client';
import { Announcement } from './models/announcement.models';

export class AnnouncementClient extends ApiClient {
    static baseUrl = `${process.env.REACT_APP_PORTAL_API}/v1`;

    static async get(): Promise<Announcement[]> {
        return await this.fetch<Announcement[]>(`${this.baseUrl}/announcement`);
    }

    static async create(announcements: Announcement): Promise<any> {
       // this.trackAnalytics('Add Announcements', announcements);
        return await this.post<any>(`${this.baseUrl}/announcement`, announcements);
    }

    static async update(announcements: Announcement): Promise<any> {
       // this.trackAnalytics('Add Announcements', announcements);
        return await this.put(`${this.baseUrl}/announcement`, announcements);
    }

    static async remove(announcementIds: string[]): Promise<any> {
       // this.trackAnalytics('Remove Announcements', announcementIds);
        return await this.delete<any>(`${this.baseUrl}/announcement`, announcementIds);
    }
}
